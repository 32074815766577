'use client'

import { ButtonGhost } from 'fukku/Button/Ghost'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import dynamic from 'next/dynamic'
import { useProductListAnalyticsProvider } from 'product-list/context/ProductListAnalyticsProvider/useProductListAnalyticsProvider'
import { useProductListFiltersProvider } from 'product-list/context/ProductListFiltersProvider/useProductListFiltersProvider'
import { useCallback } from 'react'

import styles from './FiltersButton.module.scss'

const DynamicModal = dynamic(
	() => import('../Modal/Modal').then((mod) => mod.Modal),
	{
		loading: () => <div />,
	}
)

export interface FiltersButtonProps {
	show: boolean
	onFilterClick: () => void
	onClose: () => void
}

export function FiltersButton({
	show,
	onFilterClick,
	onClose,
}: FiltersButtonProps) {
	const { t } = useLabels()
	const { nActiveFilters } = useProductListFiltersProvider()
	const { onFiltersWasOpened } = useProductListAnalyticsProvider()

	const handleFiltersClick = useCallback(() => {
		onFiltersWasOpened?.()
		onFilterClick?.()
	}, [onFilterClick, onFiltersWasOpened])

	return (
		<>
			<ButtonGhost
				className={styles.button}
				onClick={handleFiltersClick}
				data-testid='productList.filters.button'
			>
				{t('buscador.filtrar')}
				{nActiveFilters > 0 && ` (${nActiveFilters})`}
			</ButtonGhost>
			<DynamicModal show={show} onClose={onClose} />
		</>
	)
}
