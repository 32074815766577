'use client'

import type { LabelsContextType } from 'labels/types'
import { createContext, useCallback, useMemo, useState } from 'react'
import type { Labels } from 'types/labels'

export const StateLabelsContext = createContext<LabelsContextType | undefined>(
	undefined
)
export const SetStateLabelsContext = createContext<(newLabels: Labels) => void>(
	() => {
		/* do nothing */
	}
)

export function LabelsProvider({
	children,
	labels = {},
}: {
	children: React.ReactNode
	labels: Labels | undefined
}) {
	const [state, setState] = useState<Labels>(labels)

	const setLabels = useCallback((newLabels: Labels) => {
		setState((prev) => ({ ...prev, ...newLabels }))
	}, [])

	const value = useMemo(
		() => ({
			t: (key: string) => state[key] ?? key,
			labels: state,
		}),
		[state]
	)

	return (
		<StateLabelsContext.Provider value={value}>
			<SetStateLabelsContext.Provider value={setLabels}>
				{children}
			</SetStateLabelsContext.Provider>
		</StateLabelsContext.Provider>
	)
}
