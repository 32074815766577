import { env } from 'utils/envManager'

import { getHeadersAndParamsFromOptionals } from './getHeadersAndParamsFromOptionals'

export const fetcher = async (
	endpoint: string,
	language: string,
	optional?: {
		customDate: Date | null
		test: boolean
	}
) => {
	const url = new URL(`${env.NEXT_PUBLIC_SITE_BASE_URL}${endpoint}`)
	const { params, headers } = getHeadersAndParamsFromOptionals(
		{
			language,
		},
		optional
	)
	url.search = new URLSearchParams({ ...params, genesis: 'true' }).toString()

	let response
	try {
		response = await fetch(url.href, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
			next: {
				revalidate: 0,
			},
		})
	} catch (error) {
		console.error('Error', error)
	}

	if (!response?.ok) {
		throw new Error(`Error ${response?.status} ${url.href}`)
	}

	return response.json()
}
