import type {
	RangePickerValue,
	UseFiltersData,
	UseFiltersSlotItem,
} from 'product-list/types/Filters'
import type { OrderMethod } from 'product-list/types/ProductsList'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ALL_PRICES, DEFAULT_ORDER } from './constants'
import { filterItems } from './filterItems'
import { updateFilter } from './updateFilter'
import { clearSelection, getRangePriceLimits, normalizeRange } from './utils'

export interface UseFiltersValueParams {
	defaultFiltersData: UseFiltersData | undefined
	defaultOrder: OrderMethod
	defaultRangeLimit: RangePickerValue
	defaultRange: RangePickerValue | undefined
	filtersData: UseFiltersData | undefined
	originalItems: UseFiltersSlotItem[]
}

/**
 *
 * THIS HOOK IS ONLY INTENDED TO BE USED ON ProductListFiltersProvider COMPONENT.
 *
 * This hook is used to manage and provide stateful values related to product list filters.
 *
 * @param {UseFiltersValueParams} params - An object containing parameters for setting up the hook.
 * @param {UseFiltersData | undefined} params.defaultFiltersData - Initial data related to existing filters, or undefined if no filters are initially applied.
 * @param {OrderMethod} params.defaultOrder - The default order method to use when none is selected by the user.
 * @param {RangePickerValue} params.defaultRangeLimit - The default range limit value to use.
 * @param {RangePickerValue | undefined} params.defaultRange - The default range value to use when none is selected by the user.
 * @param {UseFiltersData | undefined} params.filtersData - Data related to existing filters, or undefined if no data exists yet.
 * @param {UseFiltersSlotItem[]} params.originalItems - Original items to be filtered and used for range computation.
 *
 * @returns {Object} An object containing the current state and methods for updating this state:
 *   - `selectedFilters` (Array<UseFiltersSlotItem>) - The currently selected filters.
 *   - `selectedLimitRange` (RangePickerValue) - The current limit range for price filtering.
 *   - `selectedOrder` (string) - The order method chosen by the user.
 *   - `selectedRange` (Array<number> | null) - The currently selected price range, or `null` if no range is currently set.
 *   - `setSelectedOrder` (Function) - A function that sets the order method to be used for filtering products.
 *   - `setSelectedRange` (Function) - Sets the selected range for price filtering based on user input or calculated limits.
 *   - `toggle` (Function) - A function that toggles a specific filter value within the current selection of filters.
 *   - `clear` (Function) - Resets all selections, resetting them to their default values.
 */
export const useFiltersValue = ({
	defaultFiltersData,
	defaultOrder,
	defaultRangeLimit,
	defaultRange,
	filtersData,
	originalItems,
}: UseFiltersValueParams) => {
	const defaultFilters = useMemo(
		() => defaultFiltersData?.filters ?? filtersData?.filters ?? [],
		[defaultFiltersData?.filters, filtersData?.filters]
	)

	/**
	 * Selected by user
	 */
	const [selectedFilters, setSelectedFilters] = useState(defaultFilters)
	const [selectedOrder, setSelectedOrder] = useState(defaultOrder)
	const [userRange, setUserRange] = useState(defaultRange)

	const filteredItemsWithoutRange = useMemo(
		() => filterItems(selectedFilters, originalItems, ALL_PRICES),
		[originalItems, selectedFilters]
	)

	const selectedLimitRange = useMemo(() => {
		if (filteredItemsWithoutRange.length) {
			return normalizeRange(getRangePriceLimits(filteredItemsWithoutRange))
		}
		return defaultRangeLimit
	}, [defaultRangeLimit, filteredItemsWithoutRange])

	const selectedRange = useMemo(() => {
		if (!userRange) {
			return selectedLimitRange
		}

		const range = { ...userRange }
		if (range.min < selectedLimitRange.min) {
			range.min = selectedLimitRange.min
		}

		if (range.max > selectedLimitRange.max) {
			range.max = selectedLimitRange.max
		}

		return range
	}, [selectedLimitRange, userRange])

	const reset = useCallback(() => {
		setSelectedFilters(defaultFilters)
		setSelectedOrder(defaultOrder)
		setUserRange(defaultRange)
	}, [defaultFilters, defaultOrder, defaultRange])

	// Reset default paramenters if they change
	useEffect(() => {
		reset()
	}, [reset])

	const handleClear = useCallback(() => {
		const clearedFilters = clearSelection(selectedFilters)
		setSelectedFilters(clearedFilters)
		setSelectedOrder(DEFAULT_ORDER)
		setUserRange(undefined)
	}, [selectedFilters])

	const handleSetSelectedRange = useCallback(
		(range: RangePickerValue) => setUserRange(normalizeRange(range)),
		[]
	)

	const toggle = useCallback((type: string, value: string, id?: string) => {
		setSelectedFilters((currentFilters) =>
			updateFilter(type, id, value, currentFilters)
		)
	}, [])

	return {
		selectedFilters,
		selectedLimitRange,
		selectedOrder,
		selectedRange,
		setSelectedOrder,
		toggle,
		reset,
		clear: handleClear,
		setSelectedRange: handleSetSelectedRange,
	}
}
