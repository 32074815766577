import Image from 'next/image'
import { dataTestIds } from 'plp/constants/dataTestIds'
import type { BannerItem } from 'plp/types/Banner'

import styles from './Collage.module.scss'

const POSITIONS = ['right', 'top', 'left', 'bottom']
const ONE_ITEM = 1
const FOUR_ITEMS = 4

export interface Props {
	title: string
	items: BannerItem[]
}

export default function Collage({ title, items }: Props) {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				{items.map(
					(item, index) =>
						item.image && (
							<div
								key={item.linkId}
								className={`${styles.item} ${styles[POSITIONS[(index + ONE_ITEM) % FOUR_ITEMS]]}`}
							>
								<Image
									src={item.image}
									alt=''
									fill
									sizes='(min-width: 840px) 60px, 54px'
								/>
							</div>
						)
				)}
			</div>
			<p
				className={styles.title}
				data-testid={dataTestIds.plpQuickFiltersTitle('rounded')}
			>
				{title}
			</p>
		</div>
	)
}
