import type {
	COMM_BANNER_BG_COLOR_TYPE,
	COMM_BANNER_CONTENT_COLOR_TYPE,
	COMM_BANNER_TYPE,
} from 'fukku/CommsBanners/types'
import { OutletBrand, ShopBrand } from 'types/brands'
import { Channel } from 'types/masterData'

import type { Section, SectionCTAs } from './query'

export type AvailableChannel = Channel.Outlet | Channel.Shop
export type DefaultBrandType = Record<AvailableChannel, string>

export const DefaultBrand: DefaultBrandType = {
	[Channel.Shop]: 'defaultShop',
	[Channel.Outlet]: 'defaultOutlet',
}

export type PromoBannerShopBrand = ShopBrand | typeof DefaultBrand.shop
export type PromoBannerOutletBrand = OutletBrand | typeof DefaultBrand.outlet
export type PromoBannerBrand = PromoBannerShopBrand | PromoBannerOutletBrand

export type PromoBanner = {
	title: string
	backgroundColorType: COMM_BANNER_BG_COLOR_TYPE
	contentColor: COMM_BANNER_CONTENT_COLOR_TYPE
	customBackgroundColor: string
	sections: string[]
	type: COMM_BANNER_TYPE
	channel: Channel
	brand: PromoBannerBrand
	sectionsWithoutCTAs: Section[]
	showCountdown: boolean
	timeBeforeEnd: number
	promotion: PromotionContent
}

export type PromotionContent = {
	uid: string
	title: string
	subtitle: string
	name: string
	info: string
	startDate: string | null
	discount: string
	endDate: string
	linesCTAs: boolean
	sectionCTAs: SectionCTAs[]
}
