import { IconArrowMoveAi } from 'icons/components/IconArrowMoveAi'
import { IconBackspace } from 'icons/components/IconBackspace'
import { IconDropdownNextSmall } from 'icons/components/IconDropdownNextSmall'
import { IconDropdownPrevSmall } from 'icons/components/IconDropdownPrevSmall'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { cx } from 'utils/cx'

import { ButtonIconTransparent } from '../../../Button/Icon/Transparent'

import styles from './HorizontalScrollControls.module.scss'

interface HorizontalScrollControlsProps {
	onPrevClick: () => void
	isPrevButtonDisabled: boolean
	onNextClick: () => void
	isNextButtonDisabled: boolean
	floatingArrows: boolean
}

export const HorizontalScrollControls = ({
	onPrevClick,
	onNextClick,
	isPrevButtonDisabled,
	isNextButtonDisabled,
	floatingArrows,
}: HorizontalScrollControlsProps) => {
	const {
		isDesktop,
		country: { isRtl },
	} = useMasterData()

	if (isDesktop) {
		return (
			<div className={styles.controlsContainer}>
				<ButtonIconTransparent
					disabled={isPrevButtonDisabled}
					onClick={onPrevClick}
					className={cx(
						styles.button,
						floatingArrows && styles.floatingArrows,
						floatingArrows && styles.floatingArrowsLeft
					)}
					lang='en'
					aria-label='Scroll left' // TODO translate
				>
					{floatingArrows ? (
						<IconDropdownPrevSmall rotateRtl />
					) : (
						<IconBackspace width={16} height={16} rotateRtl />
					)}
				</ButtonIconTransparent>

				<ButtonIconTransparent
					disabled={isNextButtonDisabled}
					onClick={onNextClick}
					className={cx(
						styles.button,
						floatingArrows && styles.floatingArrows,
						floatingArrows && styles.floatingArrowsRight
					)}
					lang='en'
					aria-label='Scroll right' // TODO translate
				>
					{floatingArrows ? (
						<IconDropdownNextSmall rotateRtl={isRtl} />
					) : (
						<IconArrowMoveAi width={16} height={16} rotateRtl={isRtl} />
					)}
				</ButtonIconTransparent>
			</div>
		)
	}

	return null
}
