'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'
import useSWRImmutable from 'swr/immutable'
import { PageType } from 'types/analytics'

import { getCollaborativeRecommendations } from '../services/getCollaborativeRecommendations/client'
import type { GetCollaborativeRecommendationsParams } from '../services/getCollaborativeRecommendations/client/getCollaborativeRecommendations'

export const useCollaborativeRecommendations = () => {
	const { isReady, logged, externalId } = useSession()
	const {
		country: { countryISO, languageISO },
		pageType,
	} = useMasterData()

	const doFetch =
		(isReady && logged && externalId) || pageType === PageType.ShoppingCart

	const collaborativeRecommendationsParams: GetCollaborativeRecommendationsParams =
		{ countryISO, languageISO, externalId: externalId ?? '' }

	const { data } = useSWRImmutable(
		[isReady, logged, externalId],
		() =>
			doFetch
				? getCollaborativeRecommendations(collaborativeRecommendationsParams)
				: null,
		{ revalidateOnMount: true }
	)

	return { doFetch, data }
}
