'use client'

import { IconUpLarge } from 'icons/components/IconUpLarge'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { scrollToProductListHook } from 'product-list/components/ProductListHook/ProductListHook'
import { useCallback, useEffect, useRef, useState } from 'react'
import { cx } from 'utils/cx'

import styles from './Scroll.module.scss'

const MIN_SCROLL_PX = 100
const DEFAULT_HIDE_TIMEOUT_MS = 3000

type Props = {
	hideTimeoutMs?: number
}

export function Scroll({
	hideTimeoutMs = DEFAULT_HIDE_TIMEOUT_MS,
}: Props): React.ReactNode {
	const [visible, setVisible] = useState(false)
	const lastPosition = useRef(Infinity)
	const timeoutId = useRef<number>()
	const { t } = useLabels()

	const onScroll = useCallback(() => {
		const scroll = window.scrollY
		const show = scroll < lastPosition.current && scroll > MIN_SCROLL_PX

		lastPosition.current = scroll
		setVisible(show)
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', onScroll)
		return () => window.removeEventListener('scroll', onScroll)
	}, [onScroll])

	useEffect(() => {
		if (timeoutId.current) {
			window.clearTimeout(timeoutId.current)
		}
		if (visible) {
			timeoutId.current = window.setTimeout(
				() => setVisible(false),
				hideTimeoutMs
			)
		}
		return () => {
			if (timeoutId.current) {
				window.clearTimeout(timeoutId.current)
			}
		}
	}, [visible, hideTimeoutMs])

	return (
		<button
			className={cx(styles.button, visible ? styles.visible : styles.invisible)}
			aria-label={t('accessibility.plp.scrollTop.text')}
			type='button'
			onClick={() => scrollToProductListHook({ isSmooth: true })}
		>
			<IconUpLarge height={20} width={20} color='content-inverse' />
		</button>
	)
}
