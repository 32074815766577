import { Channel } from 'types/masterData'
import { cx } from 'utils/cx'

import styles from './DiscountRateWrapper.module.scss'

interface DiscountRateWrapperProps {
	channel: Channel
	shouldShowDiscount: boolean
	showDiscountRate: boolean
	children: React.ReactNode
}

export const DiscountRateWrapper = ({
	channel,
	shouldShowDiscount,
	showDiscountRate,
	children,
}: DiscountRateWrapperProps) => {
	const hasDiscount = shouldShowDiscount && showDiscountRate
	const isHightlighted: boolean = channel === Channel.Outlet

	if (!hasDiscount) {
		return null
	}

	return (
		<div
			className={cx(
				styles.discountRateContainer,
				isHightlighted && styles.hightlightedDiscount
			)}
		>
			{children}
		</div>
	)
}
