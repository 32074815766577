import { getProductCardL } from 'product-card/constants/settings/ProductCardL/getProductCardL'
import { getProductCardM } from 'product-card/constants/settings/ProductCardM/getProductCardM'
import { getProductCardO } from 'product-card/constants/settings/ProductCardO/getProductCardO'
import { getProductCardS } from 'product-card/constants/settings/ProductCardS/getProductCardS'
import type {
	ProductCardSettingsContext,
	ResponsiveProductCardSettings,
} from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { View } from 'product-list/types/View'

const inspirational: (
	ctx: ProductCardSettingsContext
) => ResponsiveProductCardSettings = (ctx) => ({
	small: getProductCardM(ctx),
	medium: getProductCardM(ctx),
	large: getProductCardL(ctx),
})

const standard: (
	ctx: ProductCardSettingsContext
) => ResponsiveProductCardSettings = (ctx) => ({
	small: getProductCardS(ctx),
	medium: getProductCardS(ctx),
	large: getProductCardL(ctx),
})

const overview: (
	ctx: ProductCardSettingsContext
) => ResponsiveProductCardSettings = (ctx) => ({
	small: getProductCardO(ctx),
	medium: getProductCardO(ctx),
	large: getProductCardO(ctx),
})

const catalogProductCardSettings: (
	ctx: ProductCardSettingsContext
) => Record<View, ResponsiveProductCardSettings> = (ctx) => ({
	inspirational: inspirational(ctx),
	standard: standard(ctx),
	overview: overview(ctx),
})

export const getCatalogProductCardSettings = (
	ctx: ProductCardSettingsContext
) => {
	return catalogProductCardSettings(ctx)
}
