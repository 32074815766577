'use client'

import { cx } from 'classix'
import { createElement } from 'react'

import styles from './HorizontalScrollText.module.scss'

export enum HorizontalScrollTextType {
	P = 'p',
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
}

export interface HorizontalScrollTextProps {
	content: string
	type?: HorizontalScrollTextType
	className?: string
}

export const HorizontalScrollText = ({
	content,
	type = HorizontalScrollTextType.P,
	className,
}: HorizontalScrollTextProps) =>
	createElement(
		type,
		{ className: cx(styles.horizontalScrollText, className) },
		content
	)
