'use client'

import { CHATBOT_ID } from 'chatbot/constants/constants'
import { useChatbot } from 'chatbot/hooks/useChatbot/useChatbot'
import { IconChatChatS } from 'icons/components/IconChatChatS'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { cx } from 'utils/cx'

import style from './ChatbotButton.module.scss'

const CHATBOT_ICON_SIZE = 20

export interface ChatbotProps {
	version: number
}

export const ChatbotButton = ({ version }: ChatbotProps) => {
	const { t } = useLabels()
	const { isApp } = useMasterData()

	const { isChatbotActive, openChatbot } = useChatbot({
		version,
	})
	return (
		<aside
			className={cx(
				style.container,
				isChatbotActive && style.open,
				isApp && style.app
			)}
			aria-label={t('accessibility.chatbot.icon')}
		>
			{!isChatbotActive && (
				<button
					data-testid='chatbot.button.open'
					className={style.button}
					onClick={openChatbot}
					id={CHATBOT_ID}
					type='button'
				>
					<IconChatChatS
						className={style.icon}
						alt={t('accessibility.chatbot.icon')}
						height={CHATBOT_ICON_SIZE}
						width={CHATBOT_ICON_SIZE}
					/>
				</button>
			)}
			<div id='iris' data-testid='iris' />
		</aside>
	)
}
