'use client'

import { usePathname } from 'next/navigation'
import { ProductCardConsumerLayoutProvider } from 'product-card/context/ProductCardConsumerLayoutContext'
import type { ResponsiveProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { ProductCardImagesSizesProvider } from 'product-card/context/ProductCardImagesSizesContext'
import { ProductListProvider } from 'product-list/context/ProductListProvider/ProductListProvider'
import type {
	ProductListConfig,
	ProductListGroup,
	ProductListParams,
	RenderedProductListItem,
	VirtualProductsGrid,
} from 'product-list/types/ProductsList'
import type { View } from 'product-list/types/View'
import { getGridImagesSizes } from 'product-list/utils/getGridImagesSizes'
import type { Product } from 'product/types'
import { useLayoutEffect, useMemo } from 'react'

import { ProductListFiltersProvider } from '../../context/ProductListFiltersProvider/ProductListFiltersProvider'
import type { UseFiltersData } from '../../types/Filters'
import { ClientProductCardItem } from '../ClientProductCardItem/ClientProductCardItem'
import { Grid, type GridItem } from '../Grid/Grid'
import { Pagination } from '../Pagination/Pagination'
import { Sticky } from '../Sticky/Sticky'

export type ProductListProductCardResponsiveSettings = Record<
	View,
	ResponsiveProductCardSettings
>

type VirtualProductsGridWithOrder = VirtualProductsGrid & { index: number }

function updateItemsToBeRendered(
	virtualGroups: VirtualProductsGrid[],
	listCountLimit: number
): VirtualProductsGridWithOrder[] {
	let currentLimit = listCountLimit
	return virtualGroups.map((group, index) => {
		const minToRender = Math.min(
			currentLimit,
			(group.fromServer?.length ?? 0) + group.onClient.length
		)
		const updatedVirtualGroup: VirtualProductsGridWithOrder = {
			...group,
			nItemsToBeRendered: minToRender,
			index,
		}
		currentLimit -= minToRender
		return updatedVirtualGroup
	})
}

export interface ListProps {
	config: ProductListConfig
	defaultFilter?: ProductListParams
	defaultView: View
	displayFilters: boolean
	displayViewSelector: boolean
	filterResultPathname?: string
	filtersData?: UseFiltersData
	filtersOnClientEndpoint?: string
	groups: ProductListGroup[]
	name: string
	preLoadedProductsData: Product[]
	responsiveProductCardSettings: ProductListProductCardResponsiveSettings
	serverProducts: RenderedProductListItem[]
	shouldRenderSlideshowAnimation?: boolean
	onUserViewChange?: (view: View) => void
}

export function ProductList({
	config,
	defaultFilter,
	defaultView,
	displayFilters,
	displayViewSelector,
	filterResultPathname,
	filtersData,
	filtersOnClientEndpoint,
	groups,
	name,
	preLoadedProductsData,
	responsiveProductCardSettings,
	serverProducts,
	shouldRenderSlideshowAnimation,
	onUserViewChange,
}: ListProps) {
	const pathname = usePathname()

	useLayoutEffect(() => {
		/**
		 * If we are on a persisted page, we kan take advantage of native scroll
		 * restoration as we have all products initially available
		 */
		window.onpageshow = function (event) {
			if (event.persisted) {
				window.history.scrollRestoration = 'auto'
			}
		}
	}, [])

	const virtualGroups = useMemo<VirtualProductsGrid[]>(() => {
		return groups.map((group, index) => {
			if (index === 0) {
				return {
					fromServer: serverProducts,
					onClient: group.items.slice(serverProducts.length).map((item) => ({
						...item,
						render: (
							<ClientProductCardItem
								item={item}
								preLoadedProductsData={preLoadedProductsData}
							/>
						),
					})),
				}
			}
			return {
				title: group.title,
				subtitle: group.subtitle,
				onClient: group.items.map((item) => ({
					...item,
					render: (
						<ClientProductCardItem
							item={item}
							preLoadedProductsData={preLoadedProductsData}
						/>
					),
				})),
				nItemsToBeRendered: 0,
			}
		})
	}, [groups, serverProducts, preLoadedProductsData])

	const initialCount = useMemo(() => {
		if (serverProducts.length) {
			return serverProducts.length
		}
		return Math.min(
			groups.reduce((acc, group) => {
				return acc + group.items.length
			}, 0),
			config.clientPerPage
		)
	}, [config.clientPerPage, groups, serverProducts.length])

	const total = useMemo(
		() =>
			groups.reduce(
				(accumulator, group) => accumulator + group.items.length,
				0
			),
		[groups]
	)

	return (
		<ProductListProvider
			defaultView={defaultView}
			name={name}
			initialCount={initialCount}
			onUserViewChange={onUserViewChange}
			shouldRenderSlideshowAnimation={!!shouldRenderSlideshowAnimation}
		>
			{(view) => (
				<ProductListFiltersProvider
					filtersOnClientEndpoint={filtersOnClientEndpoint}
					defaultFiltersData={filtersData}
					defaultFilter={defaultFilter}
					filterResultPathname={filterResultPathname ?? pathname}
					groups={groups}
				>
					<ProductCardConsumerLayoutProvider
						layoutView={view}
						settings={responsiveProductCardSettings[view]}
					>
						<ProductCardImagesSizesProvider
							defaultImageSizes={getGridImagesSizes(view)}
						>
							<Sticky
								displayViewSelector={displayViewSelector}
								displayFilters={displayFilters}
							/>
							<Pagination total={total} perPage={config.clientPerPage}>
								{(count) =>
									updateItemsToBeRendered(virtualGroups, count).map((group) => {
										const allItems = group.fromServer
											? group.fromServer.concat(group.onClient)
											: group.onClient
										const items: GridItem[] = allItems
											.slice(0, group.nItemsToBeRendered)
											.map((item) => ({
												isFeatured: !!item.images.landscape,
												id: item.id,
												render: item.render,
											}))
										return (
											<Grid
												key={group.index}
												index={group.index}
												items={items}
											/>
										)
									})
								}
							</Pagination>
						</ProductCardImagesSizesProvider>
					</ProductCardConsumerLayoutProvider>
				</ProductListFiltersProvider>
			)}
		</ProductListProvider>
	)
}
