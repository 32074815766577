'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { useState } from 'react'
import type { FoundProductsBrand } from 'search/services/productSearchService'
import type { Brand, BrandId } from 'types/brands'

import { BrandLinkItem } from '../BrandLinkItem/BrandLinkItem'

export type BrandsWithResultsList = ({
	labelKey: string
} & FoundProductsBrand)[]

export type BrandLinksListProps = {
	brands: BrandsWithResultsList
	selectedBrand: Brand
	className?: string
	searchTerm: string
}

export function BrandLinksList({
	brands,
	selectedBrand,
	className,
	searchTerm,
}: BrandLinksListProps) {
	const { t } = useLabels()
	const [activeBrand, setActiveBrand] = useState<BrandId | null>(
		selectedBrand.id
	)

	const isActive = (brandId: BrandId | null) => {
		if (activeBrand === null) {
			return selectedBrand.id === brandId
		}

		return activeBrand === brandId
	}

	return (
		brands.length > 1 && (
			<ul className={className}>
				{brands.map((brand) => {
					const brandLabel = t(brand.labelKey)
					const accessibilityLabel = replacePlaceholders(
						t('accessibility.search.resultPage.switchLine.text'),
						{ 1: brandLabel }
					)

					return (
						<BrandLinkItem
							accessibilityLabel={accessibilityLabel}
							brandId={brand.id}
							searchTerm={searchTerm}
							key={brand.id}
							active={isActive(brand.id)}
							onChangeState={setActiveBrand}
						>
							{brandLabel}
						</BrandLinkItem>
					)
				})}
			</ul>
		)
	)
}
