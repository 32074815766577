import { Breakpoints } from 'fukku/styles/breakpoints'
import type { ImagesSizesLayout } from 'product-card/context/ProductCardImagesSizesContext/ProductCardImagesSizesContext'
import { View } from 'product-list/types/View'

const TWO = 2
const FOUR = 4
const FIVE = 5
const FULL_VIEWPORT = '100vw'
const HALF_VIEWPORT = '50vw'
const THREE_VIEWPORT = '33vw'
const SMALL_FULL = {
	maxWidth: `${Breakpoints.medium}px`,
	size: FULL_VIEWPORT,
}
const SMALL_TWO_COLUMNS = {
	maxWidth: `${Breakpoints.medium}px`,
	size: HALF_VIEWPORT,
}
const MEDIUM_TWO_COLUMNS = {
	maxWidth: `${Breakpoints.large}px`,
	size: HALF_VIEWPORT,
}
const SMALL_THREE_COLUMNS = {
	maxWidth: `${Breakpoints.medium}px`,
	size: THREE_VIEWPORT,
}
const MEDIUM_FULL = {
	maxWidth: `${Breakpoints.large}px`,
	size: FULL_VIEWPORT,
}
const MEDIUM_FIVE_COLUMNS = {
	maxWidth: `${Breakpoints.large}px`,
	size: '20vw',
}
const imagesSizes: Record<View, ImagesSizesLayout> = {
	inspirational: {
		regular: [
			SMALL_FULL,
			MEDIUM_FULL,
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '520px',
			},
			{
				minWidth: `${Breakpoints.extraLarge}px`,
				size: '520px',
			},
			{
				size: '520px',
			},
		],
		featured: [
			SMALL_FULL,
			MEDIUM_FULL,
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '1040px',
			},
			{
				minWidth: `${Breakpoints.extraLarge}px`,
				size: '1040px',
			},
			{
				size: `${Breakpoints.large}px`,
			},
		],
	},
	standard: {
		regular: [
			SMALL_TWO_COLUMNS,
			MEDIUM_TWO_COLUMNS,
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '25vw',
			},
			{
				minWidth: `${Breakpoints.extraLarge}px`,
				size: '25vw',
			},
			{
				size: `${Breakpoints.large / FOUR}px`,
			},
		],
		featured: [
			SMALL_TWO_COLUMNS,
			MEDIUM_TWO_COLUMNS,
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: HALF_VIEWPORT,
			},
			{
				minWidth: `${Breakpoints.extraLarge}px`,
				size: HALF_VIEWPORT,
			},
			{
				size: `${Breakpoints.large / TWO}px`,
			},
		],
	},
	overview: {
		regular: [
			SMALL_THREE_COLUMNS,
			MEDIUM_FIVE_COLUMNS,
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '16vw',
			},
			{
				minWidth: `${Breakpoints.extraLarge}px`,
				size: '16vw',
			},
			{
				size: `${Breakpoints.large / FOUR}px`,
			},
		],
		featured: [
			SMALL_THREE_COLUMNS,
			MEDIUM_FIVE_COLUMNS,
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '32vw',
			},
			{
				minWidth: `${Breakpoints.extraLarge}px`,
				size: '32vw',
			},
			{
				size: `${Breakpoints.large / FIVE}px`,
			},
		],
	},
}

export function getGridImagesSizes(view: View) {
	return imagesSizes[view]
}
