import { getStorePreviousUrl } from 'links/login/shared/StorePreviousUrl'

export function referrerFromProductPage(slotId: string) {
	const [productId] = slotId.split(':')
	const previousUrl = getStorePreviousUrl()
	return (
		!!productId &&
		/\/p\//.test(previousUrl) &&
		previousUrl.indexOf(productId) > -1
	)
}
