export const RESULTS_LIST_CONFIG = {
	clientPerPage: 12,
}

export const CATALOG_ID = 'Search-Results-List'

export const enum TEST_IDS {
	NO_RESULTS_PAGE = 'search.noResultPage',
	NO_RESULTS_PAGE_TITLE = 'search.noResultPage.title',
	NO_RESULTS_PAGE_BODY = 'search.noResultPage.body',
	NO_RESULTS_PAGE_CARROUSSEL = 'search.noResultPage.crossSelling.reccomendations',
	PAGE_TITLE = 'search.ResultsListPage.title',
	BRAND_LINK = 'search.ResultsListPage.brandsList.link.',
}
