'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { env } from 'utils/envManager'

export function ClientCanonicalUrl({ canonicalUrl }: { canonicalUrl: string }) {
	const params = useSearchParams()

	const absoluteCanonicalUrl = new URL(
		canonicalUrl,
		env.NEXT_PUBLIC_CURRENT_ORIGIN
	).href

	useEffect(() => {
		const currentWithoutParams = new URL(
			window.location.pathname,
			env.NEXT_PUBLIC_CURRENT_ORIGIN
		).href
		if (
			currentWithoutParams &&
			absoluteCanonicalUrl &&
			absoluteCanonicalUrl !== currentWithoutParams
		) {
			const newUrl = new URL(canonicalUrl, env.NEXT_PUBLIC_CURRENT_ORIGIN)
			newUrl.search = new URLSearchParams(params).toString()
			window.history.replaceState(null, '', `${newUrl.href}`)
		}
	}, [absoluteCanonicalUrl, canonicalUrl, params])

	return null
}
