import { useContext } from 'react'

import { StateLabelsContext } from '../../context/LabelsProvider/LabelsProvider'

/** @throws {Error} If useLabels is used without LabelsProvider */
export const useLabels = () => {
	const labels = useContext(StateLabelsContext)
	if (!labels) {
		throw new Error(
			'useLabels must be used withing a LabelsProvider and initLabels must be called before useLabels'
		)
	}
	return labels
}
