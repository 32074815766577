'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { usePlpProductListProvider } from 'plp/context/PlpProductListProvider/usePlpProductListProvider'
import { analyticsEvents } from 'plp/utils/analyticsEvents'
import { getCatalogProductCardSettings } from 'plp/utils/catalogProductCardSettings'
import { ProductList } from 'product-list/components/ProductList/ProductList'
import { ProductListAnalyticsProvider } from 'product-list/context/ProductListAnalyticsProvider/ProductListAnalyticsProvider'
import type { UseFiltersData } from 'product-list/types/Filters'
import type {
	ProductListConfig,
	ProductListGroup,
	ProductListParams,
	RenderedProductListItem,
} from 'product-list/types/ProductsList'
import { View } from 'product-list/types/View'
import type { Product } from 'product/types'
import { useMemo } from 'react'
import { useResponsiveBehaviors } from 'responsive/hooks/useResponsiveBehaviors/useResponsiveBehaviors'

export function List({
	catalogItemsData,
	filtersData,
	groupsWithFilteredProducts,
	hasServerFilters,
	listName,
	processedFilters,
	rootCatalogPathname,
	serverProducts,
}: {
	catalogItemsData: Product[]
	filtersData: UseFiltersData | undefined
	groupsWithFilteredProducts: ProductListGroup[]
	hasServerFilters: boolean
	listName: string
	processedFilters: ProductListParams | undefined
	rootCatalogPathname: string
	serverProducts: RenderedProductListItem[]
}) {
	const {
		country: { hasOnlineSale },
	} = useMasterData()

	const catalogProductCardSettings = getCatalogProductCardSettings({
		hasOnlineSale,
	})
	const { defaultView, currentView, setCurrentView } =
		usePlpProductListProvider()

	const clientPerPage = useResponsiveBehaviors<View, number>(
		{
			[View.Inspirational]: {
				default: 4,
				largeOrGreater: 8,
			},
			[View.Standard]: {
				default: 12,
				largeOrGreater: 24,
			},
			[View.Overview]: {
				default: 24,
				largeOrGreater: 28,
			},
		},
		currentView
	)

	const config = useMemo<ProductListConfig>(
		() => ({
			clientPerPage,
		}),
		[clientPerPage]
	)

	return (
		<ProductListAnalyticsProvider
			hasServerFilters={hasServerFilters}
			events={{
				onViewChange: analyticsEvents.sendLayoutViewChange,
				onFiltersWasApplied: analyticsEvents.sendApplyFiltersEvent,
				onFiltersWasClosed: analyticsEvents.sendCloseFiltersEvent,
				onFiltersWasOpened: analyticsEvents.sendOpenFiltersEvent,
				onListEndWasReached: analyticsEvents.sendPlpEndEvent,
				onZeroResults: analyticsEvents.sendNoResultFiltersEvent,
			}}
		>
			<ProductList
				config={config}
				defaultFilter={processedFilters}
				responsiveProductCardSettings={catalogProductCardSettings}
				defaultView={defaultView}
				displayFilters={true}
				displayViewSelector={true}
				filterResultPathname={rootCatalogPathname}
				filtersData={filtersData}
				groups={groupsWithFilteredProducts}
				name={listName}
				preLoadedProductsData={catalogItemsData}
				serverProducts={serverProducts}
				onUserViewChange={setCurrentView}
				shouldRenderSlideshowAnimation={true}
			/>
		</ProductListAnalyticsProvider>
	)
}
