import {
	FilterType,
	type RangePickerValue,
	type UseFiltersSlotItem,
	type UserFilter,
} from 'product-list/types/Filters'

import { atLeastOne } from './filterItems'

function getDisabledOptions({
	option,
	filters,
	originalItems,
	selectedRange,
	selectedLimitRange,
}: {
	option: {
		id: string
		label: string
	}
	filters: UserFilter[]
	originalItems: UseFiltersSlotItem[]
	selectedRange?: RangePickerValue
	selectedLimitRange?: RangePickerValue
}) {
	const currentDisabledValues: string[] = []
	if (selectedRange && selectedLimitRange) {
		const rangeToApply =
			selectedRange.min !== selectedLimitRange.min ||
			selectedRange.max !== selectedLimitRange.max
				? selectedRange
				: { min: 0, max: Number.MAX_SAFE_INTEGER }
		const atLeastOneProductMeetsWithFilters = atLeastOne(
			filters,
			originalItems,
			rangeToApply
		)
		if (!atLeastOneProductMeetsWithFilters) {
			currentDisabledValues.push(option.id)
		}
	}
	return currentDisabledValues
}

function disabledValuesFilter(
	currentFilter: UserFilter,
	selectedFilters: UserFilter[],
	originalItems: UseFiltersSlotItem[],
	selectedRange?: RangePickerValue,
	selectedLimitRange?: RangePickerValue
) {
	return currentFilter.options
		.filter((option) => !currentFilter.selection.includes(option.id))
		.reduce((disabledValues, option) => {
			const simulatedFilters = selectedFilters.map((filter) => {
				if (filter.type !== currentFilter.type) {
					return filter
				}

				if (
					filter.type === FilterType.generic &&
					currentFilter.type === FilterType.generic &&
					filter.id !== currentFilter.id
				) {
					return filter
				}

				return { ...filter, selection: [option.id] }
			})
			return disabledValues.concat(
				...getDisabledOptions({
					option,
					originalItems,
					selectedRange,
					selectedLimitRange,
					filters: simulatedFilters,
				})
			)
		}, [] as string[])
}

export function getDisabledValues(
	selectedFilters: UserFilter[],
	originalItems: UseFiltersSlotItem[],
	selectedRange?: RangePickerValue,
	selectedLimitRange?: RangePickerValue
) {
	let currentDisabledValues: string[] = []
	selectedFilters
		.filter((currentFilter) => currentFilter?.options)
		.forEach((currentFilter) => {
			const filterDisabledValues = disabledValuesFilter(
				currentFilter,
				selectedFilters,
				originalItems,
				selectedRange,
				selectedLimitRange
			)
			currentDisabledValues = [
				...currentDisabledValues,
				...filterDisabledValues,
			]
		})
	return currentDisabledValues
}
