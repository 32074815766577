'use client'

import { useSegmentationProvider } from 'plp/context/SegmentationProvider/useSegmentationProvider'
import { useMemo } from 'react'
import { env } from 'utils/envManager'
import { ClientLink } from 'utils/link/client'

import { CATALOG_URL_ID, SEGMENTATION_PARAM } from '../constants'
import type { PlpLinkProps } from '../types'

/* eslint react-hooks/exhaustive-deps: warn */

export function isCatalogUrl(url: string): boolean {
	return url.includes(`/${CATALOG_URL_ID}/`)
}

export function PlpClientLink({
	href,
	className,
	children,
	linkProps,
	onClick,
	catalogId,
	metaAs,
}: PlpLinkProps): React.ReactNode {
	const { segments } = useSegmentationProvider()

	const url = useMemo(() => {
		const baseUrl = href.startsWith('/')
			? new URL(href, env.NEXT_PUBLIC_CURRENT_ORIGIN)
			: new URL(href)

		if (segments && catalogId) {
			const segment = segments[catalogId]
			if (segment) {
				baseUrl.searchParams.set(SEGMENTATION_PARAM, segment)
			}
		}

		return baseUrl.href
	}, [catalogId, segments, href])

	return (
		<>
			{metaAs && <meta itemProp={metaAs} content={url} />}
			<ClientLink
				className={className}
				href={url}
				isLocalizedPath
				prefetch={false}
				onClick={onClick}
				linkProps={linkProps}
			>
				{children}
			</ClientLink>
		</>
	)
}
