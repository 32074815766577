'use client'

import { usePathname } from 'next/navigation'
import { ProductCardAnimationProvider } from 'product-card/context/ProductCardAnimationContext'
import { ProductCardAnimation } from 'product-card/types'
import { useProductListProvider } from 'product-list/context/ProductListProvider/useProductListProvider'
import { View } from 'product-list/types/View'
import { referrerFromProductPage } from 'product-list/utils/referrerFromProductPage'
import { referrerFromSameUrl } from 'product-list/utils/referrerFromSameUrl'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { isSessionStorageAvailable } from 'utils/webStorage/sessionStorage'

/* eslint react-hooks/exhaustive-deps: warn */

type Props = {
	isFirstProductCard: boolean
	children: React.ReactNode
}

export const AddProductCardAnimation = ({
	isFirstProductCard,
	children,
}: Props) => {
	const pathname = usePathname()
	const { storedState, view, shouldRenderSlideshowAnimation } =
		useProductListProvider()
	const { isLargeOrGreater } = useResponsive()
	const [animation, setAnimation] = useState(ProductCardAnimation.FadeIn)
	const slideshowAnimationDisplayed = useRef(false)

	const shouldRenderSlideshow = useMemo(() => {
		if (!shouldRenderSlideshowAnimation) {
			return false
		}
		const isServer = !isSessionStorageAvailable()
		const isFirstVisit =
			!(storedState && referrerFromProductPage(storedState.slotId)) &&
			!referrerFromSameUrl(pathname)

		if (isServer || !isFirstVisit || isLargeOrGreater) {
			return false
		}

		return isFirstProductCard
	}, [
		shouldRenderSlideshowAnimation,
		storedState,
		pathname,
		isLargeOrGreater,
		isFirstProductCard,
	])

	// The var shouldRenderSlideshow is expected to be true and then turn false when the the last page url stored in session storage matches the current one.
	// For this reason, we must persist the initial value
	const initialAnimation = useRef(
		shouldRenderSlideshow
			? ProductCardAnimation.Slideshow
			: ProductCardAnimation.FadeIn
	)

	useEffect(() => {
		if (
			initialAnimation.current === ProductCardAnimation.Slideshow &&
			view === View.Standard &&
			!slideshowAnimationDisplayed.current
		) {
			slideshowAnimationDisplayed.current = true
			setAnimation(ProductCardAnimation.Slideshow)
		}
	}, [view])

	useEffect(() => {
		if (view !== View.Standard) {
			setAnimation(ProductCardAnimation.FadeIn)
		}
	}, [view])

	return (
		<ProductCardAnimationProvider animation={animation}>
			{children}
		</ProductCardAnimationProvider>
	)
}
