import { sendAppEventFactory } from './sendAppEvent'

export const AnalyticsCommand = 'logEvent'
export const AnalyticsListener = 'firebase'

interface OptionalParams {
	name: string
	parameters?: Record<string, unknown>
}

export function sendAppAnalytic(
	name: string,
	parameters?: Record<string, unknown>
) {
	const optionalParams: OptionalParams = {
		name,
		parameters: { ...(parameters ?? {}), event: name },
	}

	sendAppEventFactory({
		command: AnalyticsCommand,
		listener: AnalyticsListener,
		optionalParams,
	})
}
