'use client'

import { useFavorite } from 'favorite/hooks/useFavorite'
import type { FavoriteButtonSmallProps } from 'favorite/types/types'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { IconFavoffLSmall } from 'icons/components/IconFavoffLSmall'
import { IconFavoffSSmall } from 'icons/components/IconFavoffSSmall'
import { useMemo } from 'react'
import { cx } from 'utils/cx'

import styles from './FavoriteButtonSmall.module.scss'

const ICON_SMALL_WIDTH = 16
const ICON_SMALL_HEIGHT = 16

/**
 * Favorite small button used mainly in product card
 * where the heart icon must be 16x15px and the button size 24x24px
 */
export const FavoriteButtonSmall = ({
	productId,
	className = '',
	onClickCallback,
	dataTestIds,
	notification = false,
	selectedColorId,
	location,
}: FavoriteButtonSmallProps) => {
	const { isFavorite, handleClick, buttonLabel, showButton } = useFavorite({
		productId,
		selectedColorId,
		notification,
		onClickCallback,
		location,
		isSavedForLater: false,
	})

	const iconProps = useMemo(
		() => ({
			width: ICON_SMALL_WIDTH,
			height: ICON_SMALL_HEIGHT,
			alt: buttonLabel,
		}),
		[buttonLabel]
	)

	if (!showButton) {
		return null
	}

	return (
		<ButtonIconTransparent
			className={cx(styles.small, className)}
			onClick={handleClick}
			data-testid={isFavorite ? dataTestIds.active : dataTestIds.inactive}
		>
			{isFavorite ? (
				<IconFavoffSSmall {...iconProps} />
			) : (
				<IconFavoffLSmall {...iconProps} />
			)}
		</ButtonIconTransparent>
	)
}
