'use client'

import { useProductListProvider } from 'product-list/context/ProductListProvider/useProductListProvider'
import { useVirtualPages } from 'product-list/hooks/useVirtualPages'
import { type ReactNode } from 'react'

import { VIRTUAL_LIST_CLASS } from '../../hooks/useVirtualPages/useVirtualPages'

export function Pagination({
	children,
	total,
	perPage,
}: {
	children?: (count: number) => ReactNode
	total: number
	perPage: number
}) {
	const { count } = useProductListProvider()
	useVirtualPages(total, perPage)

	return <div className={VIRTUAL_LIST_CLASS}>{children?.(count)}</div>
}
