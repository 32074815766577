'use client'

import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'
import { useRef } from 'react'

import { CountryLanguageButton } from './CountryLanguageButton/CountryLanguageButton'

const DynamicCountryLanguage = dynamic(() =>
	import('country-language/components/CountryLanguage/CountryLanguage').then(
		(mod) => mod.CountryLanguage
	)
)

export function ChangeCountryLanguage(): React.ReactNode {
	const {
		country: { countryName, isMultiLanguage, languageName },
	} = useMasterData()
	const modalRef = useRef<ModalAPI>(null)
	const { t } = useLabels()

	const handleOpen = () => {
		modalRef.current?.show()
	}

	const closeModal = (): void => {
		modalRef.current?.close()
	}

	return (
		<>
			<CountryLanguageButton
				onClick={handleOpen}
				countryName={countryName}
				isMultiLanguage={isMultiLanguage}
				languageName={languageName}
			/>
			<Modal
				ref={modalRef}
				name='country-language-modal'
				type={MODAL_TYPE.M}
				data-testid='modalCountryLanguage'
				title={t('countrySelector.footer.changeCountry.title')}
			>
				<DynamicCountryLanguage closeModal={closeModal} />
			</Modal>
		</>
	)
}
