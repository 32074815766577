import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { getProductCardLCrossSelling } from 'product-card/constants/settings/ProductCardL/getProductCardLCrossSelling'
import { getProductCardSCrossSelling } from 'product-card/constants/settings/ProductCardS/getProductCardSCrossSelling'
import type { ResponsiveProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { ProductCardIcon } from 'product-card/types'
import { useMemo } from 'react'

interface UseProductCardCrossSellingSettingsProps {
	hasSizeSheetButton: boolean
	hasFavoriteButton?: boolean
}

export const useProductCardCrossSellingSettings = ({
	hasSizeSheetButton,
	hasFavoriteButton,
}: UseProductCardCrossSellingSettingsProps): ResponsiveProductCardSettings => {
	const {
		country: { hasOnlineSale },
	} = useMasterData()

	return useMemo(() => {
		const crossSellingSettings = {
			hasSizeSheetButton,
			showActionIcon: hasFavoriteButton
				? ProductCardIcon.Favorite
				: ProductCardIcon.None,
		}

		const smallAndMediumSettings = {
			...getProductCardSCrossSelling({ hasOnlineSale }),
			...crossSellingSettings,
		}

		return {
			small: smallAndMediumSettings,
			medium: smallAndMediumSettings,
			large: {
				...getProductCardLCrossSelling({ hasOnlineSale }),
				...crossSellingSettings,
			},
		}
	}, [hasSizeSheetButton, hasFavoriteButton, hasOnlineSale])
}
