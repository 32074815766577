import type {
	ASC,
	DESC,
	RECOMMENDED,
} from 'product-list/context/ProductListFiltersProvider/constants'

import type { OrderMethod } from './ProductsList'

export interface FiltersSearchParams {
	order?: OrderMethod
	filters?: string
	range?: string
	segment?: string
}

export interface RangePickerValue {
	min: number
	max: number
}

export enum FilterType {
	generic = 'generic',
	colorGroups = 'colorGroups',
	sizes = 'sizes',
	price = 'price',
	order = 'order',
}

export enum FilterId {
	subfamilies = 'subfamilies',
}

export enum FilterAction {
	apply = 'apply',
	close = 'close',
	clear = 'clear',
}

export interface ColorsFilter {
	type: FilterType.colorGroups
	colorGroups: { id: string; label: string }[]
}

export interface PriceFilter {
	type: FilterType.price
	onSale?: boolean
}

export interface SizesFilter {
	type: FilterType.sizes
	sizes: string[]
}

export interface OrderFilter {
	type: FilterType.order
}

export interface GenericFilter {
	type: FilterType.generic
	id: FilterId.subfamilies
	label: string
	choices: { id: string; label: string }[]
	multipleChoice: boolean
}

export type Filter =
	| ColorsFilter
	| PriceFilter
	| SizesFilter
	| OrderFilter
	| GenericFilter

export type UserFilter = Filter & {
	label: string
	selection: string[]
	multipleChoice: boolean
	options: { id: string; label: string }[]
}

export interface FilterInfo {
	id?: string
	type: FilterType
}

export interface FiltersData {
	filters: Filter[]
	items: Record<string, UseFiltersItem>
	generic?: Record<string, Record<string, string[]>>
}

export interface FiltersItemData {
	id: string
	index: number
	productId: string
	colorGroup: string
	sizes: string[]
	price: number
	onSale?: boolean
}

export interface UseFiltersItem {
	productId: string
	colorGroup: string
	sizes: string[]
	price: number
	onSale?: boolean
}

export interface UseFiltersSlotItem extends UseFiltersItem {
	id: string
	index: number
	subfamilies?: string[]
}

export enum PRICE_TYPE {
	MIN = 'min',
	MAX = 'max',
}

export interface FiltersChildProps {
	show: boolean
	onClose?: () => void
}

export interface HeaderProps {
	hasSelection: boolean
	selectedCount: number
}

export interface ApplyArgs {
	selectedFiltersFromTags?: UserFilter[]
	selectedRangeFromTags?: RangePickerValue
	selectedOrderFromTags?: string
	deletedType?: string
}

export interface SerializedParams {
	selectedFilters: UserFilter[]
	selectedOrder: string
	selectedRange?: RangePickerValue
	selectedLimitRange?: RangePickerValue
}

export interface UseFiltersData {
	filters: UserFilter[]
	catalogItemsData: UseFiltersSlotItem[] | undefined
	range: RangePickerValue | undefined
}

export type SelectedOrderIn =
	| typeof RECOMMENDED
	| typeof ASC
	| typeof DESC
	| undefined

export interface SelectedFilters {
	selectedOrder: string | undefined
	selectedRange: RangePickerValue | undefined
	selectedLimitRange: RangePickerValue | undefined
	selectedFilters: UserFilter[]
	selectedCount: number
}
