'use client'

import { ButtonInspirational } from 'fukku/Button/Inspirational'
import { type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { StoreLocatorModal } from 'maps/components/StoreLocatorModal/StoreLocatorModal'
import { useRef } from 'react'

import styles from '../NavigationLinkItem.module.scss'

export const StoreLocatorButton = () => {
	const { t } = useLabels()

	const storeLocatorModalRef = useRef<ModalAPI>(null)

	const onClick = () => {
		storeLocatorModalRef.current?.show()
	}

	return (
		<>
			<ButtonInspirational
				aria-label={t('storeFinder.search.stores.label')}
				className={styles.navigationLink}
				onClick={onClick}
			>
				{t('storeFinder.search.stores.label')}
			</ButtonInspirational>
			<StoreLocatorModal storeLocatorModalRef={storeLocatorModalRef} />
		</>
	)
}
