export const dataTestIds = {
	plpProductsListH1Seo: 'plp.products.list.h1Seo',
	plpProductsListTextoSeo: 'plp.products.list.textoSeo',
	plpSlot: 'plp.slot',
	plpProductsListSubfamily: 'plp.products.list.subfamily',
	plpQuickFilters: (type: string) => `plp.quickfilters.${type}`,
	plpQuickFiltersTitle: (type: string) => `plp.quickfilters.${type}.title`,
	plpQuickFiltersOption: (type: string, index: number) =>
		`plp.quickfilters.${type}.option-${index}`,
	plpQuickFiltersOptions: (type: string) => `plp.quickfilters.${type}.options`,
	plpQuickFiltersHorizontalScroll: (type: string) =>
		`plp.quickfilters.${type}.horizontalScroll`,
	plpQuickFiltersDescription: (type: string, index: number) =>
		`plp.quickfilters.${type}.description.${index}`,
	plpProductsListSubfamilyItem: (label: string, isActive: boolean) =>
		`plp.products.list.subfamily.${label.split('_').pop()}${isActive ? '_select' : ''}`,
	plpProductCard: 'plp.product.card',
}
