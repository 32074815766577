'use client'

import { useCookiesBanner } from 'cookies/hooks/useCookiesBanner/useCookiesBanner'
import { ButtonInspirational } from 'fukku/Button/Inspirational'

import styles from '../LegalLinks.module.scss'

export function CookiesButton({
	cookiesBanner,
}: {
	cookiesBanner: { key: string }
}) {
	const { showCookiesConfiguration } = useCookiesBanner()

	return (
		<ButtonInspirational
			className={styles.legalLink}
			onClick={showCookiesConfiguration}
		>
			{cookiesBanner.key}
		</ButtonInspirational>
	)
}
