'use client'

import { SelectPromotion } from 'analytics/components/SelectPromotion/SelectPromotion'
import { CLIENT_LINK_TYPE } from 'fukku/TextLink/client'
import dynamic from 'next/dynamic'
import { useLayoutEffect, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { applyStylesIf, cx } from 'utils/cx'

import type {
	CtaWithImage,
	PointOfInterestImage,
} from '../../../../../../Home.type'
import HeroBannerFullHeightWrapper from '../../../components/HeroBannerFullHeightWrapper/HeroBannerFullHeightWrapper'
import { HeroBannerImage } from '../../../components/HeroBannerImage/HeroBannerImage'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopMultibrand.module.scss'
import texts from 'fukku/styles/texts.module.scss'

const HeroBannerShopMultibrandTitle = dynamic(() =>
	import('../HeroBannerShopMultibrandTitle/HeroBannerShopMultibrandTitle').then(
		(mod) => mod.HeroBannerShopMultibrandTitle
	)
)

const ClientTextLink = dynamic(() =>
	import('fukku/TextLink/client').then((mod) => mod.ClientTextLink)
)

interface HeroBannerShopMultibrandContentProps {
	titleKey: string
	landscapeImage: PointOfInterestImage | null
	portraitImage: PointOfInterestImage | null
	ctasWithImage: CtaWithImage[]
	inverse: boolean
	isPrioritySlot: boolean
}

export function HeroBannerShopMultibrandContent({
	titleKey,
	landscapeImage,
	portraitImage,
	ctasWithImage,
	inverse,
	isPrioritySlot,
}: HeroBannerShopMultibrandContentProps) {
	const ctaTextClassName = cx(
		animationStyles.opacityFadeIn,
		styles.ctaText,
		texts.uppercaseM,
		applyStylesIf(inverse, styles.inverse)
	)
	const [hoveredCtaId, setHoveredCtaId] = useState('')
	const { isLargeOrGreater } = useResponsive()
	const [isFirstRender, setIsFirstRender] = useState(true)

	useLayoutEffect(() => {
		setIsFirstRender(false)
	}, [])

	const handleBrandImageChange = (newHoveredCtaId: string) => {
		setHoveredCtaId(newHoveredCtaId)
	}

	const handleDefaultImageChange = () => {
		setHoveredCtaId('')
	}

	return (
		<HeroBannerFullHeightWrapper isPrioritySlot={isPrioritySlot}>
			{landscapeImage && portraitImage && (
				<HeroBannerImage
					priority={isPrioritySlot}
					landscapeImage={landscapeImage}
					portraitImage={portraitImage}
					className={cx(
						applyStylesIf(Boolean(hoveredCtaId), styles.hiddenImage)
					)}
				/>
			)}
			{ctasWithImage.map(({ ctaId, image }) => {
				return (
					<HeroBannerImage
						key={ctaId}
						image={image}
						className={cx(
							applyStylesIf(ctaId !== hoveredCtaId, styles.hiddenImage)
						)}
					/>
				)
			})}
			{!isFirstRender && (
				<div className={styles.heroBannerMultibrandTextCenterWrapper}>
					<HeroBannerShopMultibrandTitle
						titleKey={titleKey}
						inverse={inverse}
						isLargeOrGreater={isLargeOrGreater}
					/>
					{ctasWithImage.length === 1 ? (
						<div className={ctaTextClassName}>{ctasWithImage[0].text}</div>
					) : (
						<nav className={styles.ctasWrapper}>
							{ctasWithImage.map((cta) => {
								return (
									<button
										className={styles.ctaButton}
										key={cta.ctaId}
										onMouseEnter={() => handleBrandImageChange(cta.ctaId)}
										onMouseLeave={handleDefaultImageChange}
										tabIndex={-1}
									>
										<SelectPromotion promotion={cta.promotion}>
											<ClientTextLink
												href={cta.href}
												type={CLIENT_LINK_TYPE.INSPIRATIONAL}
												inverse={inverse}
												linkProps={cta.linkProps}
												className={cx(
													animationStyles.opacityFadeIn,
													styles.ctaLink
												)}
												isLocalizedPath={cta.isLocalizedPath}
												withLegacyLocale={cta.withLegacyLocale}
											>
												{cta.text}
											</ClientTextLink>
										</SelectPromotion>
									</button>
								)
							})}
						</nav>
					)}
				</div>
			)}
		</HeroBannerFullHeightWrapper>
	)
}
