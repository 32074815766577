'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { ButtonInspirational } from 'fukku/Button/Inspirational'
import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { FooterLinkTypes } from 'legal/constants/footerLinkTypes'
import dynamic from 'next/dynamic'
import { type ReactNode, useEffect, useRef } from 'react'

const ClientPrivacyPolicy = dynamic(() =>
	import('legal/components/policies/PrivacyPolicy/client').then(
		(mod) => mod.ClientPrivacyPolicy
	)
)

const ClientTermsAndConditions = dynamic(() =>
	import('legal/components/policies/TermsAndConditions/client').then(
		(mod) => mod.ClientTermsAndConditions
	)
)

export const FooterLinkAsModal = ({
	title,
	type,
	className,
}: {
	title: string
	type: FooterLinkTypes
	className?: string
}): ReactNode => {
	const modalRef = useRef<ModalAPI>(null)
	const { show: modalCookiesOpened } = useCookiesContext()

	const openModal = () => {
		modalRef.current?.show()
	}

	useEffect(() => {
		if (modalCookiesOpened) {
			modalRef.current?.close()
		}
	}, [modalCookiesOpened])

	return (
		<>
			<ButtonInspirational
				onClick={openModal}
				className={className}
				id={`footer.${type}.link`}
				data-testid={`footer.${type}.link`}
			>
				{title}
			</ButtonInspirational>
			<Modal ref={modalRef} name={type} title={title} type={MODAL_TYPE.XL}>
				{type === FooterLinkTypes.PRIVACY_POLICY && (
					<ClientPrivacyPolicy title={title} />
				)}
				{type === FooterLinkTypes.TERMS_AND_CONDITONS && (
					<ClientTermsAndConditions title={title} />
				)}
			</Modal>
		</>
	)
}
