'use client'

import { SearchOrigin } from 'analytics/events/recommended/search/search'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useState } from 'react'
import type { BrandId } from 'types/brands'
import { Keyboard } from 'types/keyboard'
import { cx } from 'utils/cx'

import { TEST_IDS } from '../../constants/boxInput'
import { useSearch } from '../../hooks/useSearch'
import type { SearchTerm } from '../../types/search'

import styles from './SearchBox.module.scss'
import text from 'fukku/styles/texts.module.scss'

// TODO: the header icon or text will be a link to landing search from now on.
// TODO: landing page should take the non-yet-existing search box component from fukku.

type SearchBoxProps = {
	searchTerm?: SearchTerm
	selectedBrandId: BrandId
}

export function SearchBox({
	searchTerm,
	selectedBrandId,
}: SearchBoxProps): React.ReactNode {
	const [searchInputValue, setSearchInputValue] = useState<string>(
		searchTerm || ''
	)
	const { t } = useLabels()
	const handleSearch = useSearch({
		brandId: selectedBrandId,
		searchTerm: searchInputValue.trim(),
		origin: SearchOrigin.WRITTEN,
		callbackOnSearch: () => console.log('show search loader'),
	})

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(event.target.value)
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === Keyboard.Tab) {
			event.preventDefault()
		}

		if (searchInputValue !== '' && event.key === Keyboard.Enter) {
			handleSearch()
		}
	}

	return (
		<form onSubmit={handleSearch}>
			<input
				autoComplete='off'
				className={cx(styles.input, text.uppercaseM, text.inputText)}
				data-testid={TEST_IDS.SEARCH_INPUT}
				id='searchInput'
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				placeholder={t('search.search.label')}
				type='search'
				value={searchInputValue}
				//eslint-disable-next-line jsx-a11y/no-autofocus -- we allways need to auto-focus the input always when it mounts (it comes from a user interaction)
				autoFocus={!searchTerm}
			/>
		</form>
	)
}
