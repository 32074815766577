'use client'

import { useSearchParams } from 'next/navigation'
import { View } from 'product-list/types/View'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { PRODUCT_LIST_QUERY_PARAMS } from '../../constants/queryParams'
import {
	type ProductListState,
	clearProductListState,
	getProductListState,
} from '../../utils/productListState'
import { referrerFromProductPage } from '../../utils/referrerFromProductPage'
import { useProductListAnalyticsProvider } from '../ProductListAnalyticsProvider/useProductListAnalyticsProvider'

export interface ProductListContextType {
	view: View
	defaultView: View
	name: string
	count: number
	lastSlotId: string | undefined
	storedState: ProductListState | undefined
	shouldRenderSlideshowAnimation: boolean
	setCount: Dispatch<SetStateAction<number>>
	setView: Dispatch<SetStateAction<View>>
}

interface ProductListProviderProps {
	defaultView: View
	initialCount: number
	name: string
	shouldRenderSlideshowAnimation: boolean
	children?: (view: View) => React.ReactNode
	onUserViewChange?: (view: View) => void
}

const defaultContext: ProductListContextType = {
	count: 0,
	defaultView: View.Standard,
	lastSlotId: '',
	name: 'product-list',
	shouldRenderSlideshowAnimation: false,
	storedState: undefined,
	view: View.Standard,
	setView: () => {},
	setCount: () => {},
}

function isView(rawView: string) {
	return Object.values(View).includes(rawView as View)
}

export const ProductListContext =
	createContext<ProductListContextType>(defaultContext)

export const ProductListProvider = ({
	defaultView,
	initialCount,
	name,
	shouldRenderSlideshowAnimation,
	children,
	onUserViewChange,
}: ProductListProviderProps) => {
	const searchParams = useSearchParams()

	const { setLayoutView } = useProductListAnalyticsProvider()
	const [count, setCount] = useState(initialCount)

	const initialView = useMemo(() => {
		const qpView = searchParams.get(PRODUCT_LIST_QUERY_PARAMS.view)
		if (qpView && isView(qpView)) {
			return qpView as View
		} else {
			return defaultView
		}
	}, [defaultView, searchParams])

	const [view, setView] = useState(initialView)
	const [lastSlotId, setLastSlotId] = useState<string>()

	const storedState = useMemo(() => getProductListState(name), [name])

	const contextState: ProductListContextType = useMemo<ProductListContextType>(
		() => ({
			count,
			defaultView,
			lastSlotId,
			name,
			shouldRenderSlideshowAnimation,
			storedState,
			view,
			setView,
			setCount,
		}),
		[
			view,
			defaultView,
			name,
			count,
			lastSlotId,
			storedState,
			shouldRenderSlideshowAnimation,
		]
	)

	// Restore values from session storage
	useEffect(() => {
		if (storedState) {
			if (referrerFromProductPage(storedState.slotId)) {
				setView(storedState.view)
				setCount(storedState.count)
				setLastSlotId(storedState.slotId)
			}
			clearProductListState(name)
		}
	}, [name, storedState])

	useEffect(() => {
		setLayoutView?.(view)
	}, [view, setLayoutView])

	useEffect(() => {
		onUserViewChange?.(view)
	}, [onUserViewChange, view])

	return (
		<ProductListContext.Provider value={contextState}>
			{children?.(view)}
		</ProductListContext.Provider>
	)
}
