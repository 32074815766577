'use client'

import { ClientLink } from 'utils/link/client'

import styles from './HorizontalScrollLink.module.scss'
import text from 'fukku/styles/texts.module.scss'

export interface HorizontalScrollLinkProps {
	content?: string
	href?: string
}

export const HorizontalScrollLink = ({
	content,
	href,
}: HorizontalScrollLinkProps) => {
	const isHorizontalScrollLink = content && href

	if (isHorizontalScrollLink) {
		return (
			<ClientLink
				className={`${text.uppercaseM} ${styles.horizontalScrollLink}`}
				href={href}
			>
				{content}
			</ClientLink>
		)
	}

	return null
}
