import { pushToDatalayer } from '../../../utils/pushToDatalayer/pushToDatalayer'

type SearchParams = {
	search_term: string
	type: SearchOrigin
}

export enum SearchOrigin {
	AUTOCOMPLETE = 'autocomplete',
	PHOTO = 'photo',
	POPULAR = 'popular_search',
	RECENT = 'recent_search',
	WRITTEN = 'written',
}

export const sendSearchEvent = (search: SearchParams): void => {
	const eventName = 'search'

	const payload = {
		event: 'GA4-search',
		event_name: eventName,
		search: {
			...search,
			genesis: true,
		},
	}

	pushToDatalayer(payload)
}
