'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { COOKIES_PREFERENCES } from 'cookies/types'
import { isInitialStep } from 'cookies/utils/getCookiesBannerStep'
import dynamic from 'next/dynamic'

import { CookiesWrapper } from '../CookiesWrapper'
import { CookiesNotice } from './CookiesNotice'

const CookiesSettings = dynamic(() =>
	import('./CookiesSettings').then((mod) => mod.CookiesSettings)
)

export const isCookiesPreference = (
	value: string
): value is COOKIES_PREFERENCES =>
	Object.values(COOKIES_PREFERENCES).includes(value as COOKIES_PREFERENCES)

export const CookiesContent = () => {
	const { step } = useCookiesContext()

	if (isInitialStep(step)) {
		return (
			<CookiesWrapper>
				<CookiesNotice />
			</CookiesWrapper>
		)
	}

	return (
		<CookiesWrapper>
			<CookiesSettings />
		</CookiesWrapper>
	)
}
