import { PLPQueryParams } from 'plp/constants/queryParams'
import { ALL_PRODUCT_LIST_QUERY_PARAMS } from 'product-list/constants/queryParams'
import { env } from 'utils/envManager'

export const QUERY_PARAMS_BLACKLIST = [
	...ALL_PRODUCT_LIST_QUERY_PARAMS,
	PLPQueryParams.collection,
]

export function getSubsectionUrl(
	path: string,
	searchParams: URLSearchParams,
	collection?: string
): URL {
	const url = new URL(path, env.NEXT_PUBLIC_SITE_BASE_URL)
	if (collection) {
		// Preserve external query params
		searchParams.forEach((value, key) => {
			if (!QUERY_PARAMS_BLACKLIST.includes(key as PLPQueryParams)) {
				url.searchParams.append(key, value)
			}
		})
		url.searchParams.append(PLPQueryParams.collection, collection)
	}
	return url
}
