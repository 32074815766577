import { type MenuEntry, MenuSectionType } from '../../types/menu'

export function determineEntryUrl(section: MenuEntry) {
	const { type, url, legacyUrl } = section

	const featureFlagMap: { [key: string]: boolean } = {
		[MenuSectionType.Brand]: true,
		[MenuSectionType.Multibrand]: true,
		[MenuSectionType.Edit]: false,
		[MenuSectionType.Catalog]: true,
		[MenuSectionType.Inspirational]: true,
		[MenuSectionType.Editorial]: true,
	}

	if (featureFlagMap[type]) {
		return url
	}

	return legacyUrl
}
