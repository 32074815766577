'use client'

import { HorizontalScroll } from 'fukku/HorizontalScroll'
import { dataTestIds } from 'plp/constants/dataTestIds'
import type { BannerItem } from 'plp/types/Banner'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { Option } from './Option/Option'

import styles from './Options.module.scss'

const TOO_MANY_ITEMS = 6

interface Props {
	data: BannerItem[]
	catalogId: string
	collection?: string
}

export function Options({ data, catalogId, collection }: Props) {
	const { isLessThanLarge } = useResponsive()

	const showWithFewItemsRatio = data.length <= TOO_MANY_ITEMS || isLessThanLarge

	const items = data.map((item, indexItem) => {
		if (item.image) {
			const hasCollection = item.collection ? `:${collection ?? ''}` : ''
			return (
				<Option
					key={`${item.catalogId}${item.collection ?? ''}`}
					item={item}
					isActive={
						item.catalogId === `${catalogId}${hasCollection}` ||
						(!collection && indexItem === 0)
					}
					showWithFewItemsRatio={showWithFewItemsRatio}
					indexItem={indexItem}
				/>
			)
		}
		return null
	})

	const currentPosition = data.findIndex((item) => {
		const hasCollection = item?.collection ? `:${collection ?? ''}` : ''
		return item?.catalogId === `${catalogId}${hasCollection}`
	})

	return (
		<div
			className={styles.container}
			data-testid={dataTestIds.plpQuickFiltersOptions('squared')}
		>
			{isLessThanLarge ? (
				<HorizontalScroll
					showPaddingCarousel={false}
					className={styles.horizontalScroll}
					showHeader={false}
					titleProps={{ content: '', className: '' }}
					data-testid={dataTestIds.plpQuickFiltersHorizontalScroll('squared')}
					initialPosition={currentPosition}
				>
					{items}
				</HorizontalScroll>
			) : (
				items
			)}
		</div>
	)
}
