'use client'

import { type Dispatch, createContext, useCallback, useState } from 'react'
import type { UserData } from 'types/userData'

import { useHandleUserData } from '../hooks/useHandleUserData'

export const UserDataContext = createContext<UserDataProviderProps | undefined>(
	undefined
)

export const UserDataDispatchContext = createContext<
	Dispatch<Partial<UserDataProviderProps>> | undefined
>(undefined)

export interface UserDataProviderProps extends UserData {
	loading: boolean
}

const initialState: UserDataProviderProps = {
	loading: true,
	userCountryISO: null,
	userLanguageISO: null,
	userBrandID: null,
	userSubBrandID: null,
}

export const UserDataProvider = ({
	children,
}: {
	children: React.ReactNode
}): React.ReactNode => {
	const [state, setState] = useState(initialState)
	const dispatch = useCallback(
		(newState: Partial<UserDataProviderProps>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)
	useHandleUserData(dispatch)

	return (
		<UserDataContext.Provider value={state}>
			<UserDataDispatchContext.Provider value={dispatch}>
				{children}
			</UserDataDispatchContext.Provider>
		</UserDataContext.Provider>
	)
}
