import type { MINMAX, NO_SALES, SALES } from 'plp/constants/analytics'
import type {
	NEW_COLLECTION,
	ON_SALE,
} from 'product-list/context/ProductListFiltersProvider/constants'
import type {
	PRICE_TYPE,
	RangePickerValue,
	UserFilter,
} from 'product-list/types/Filters'

import type { ColumnsNumber } from './Options'

type FilterPrice = PRICE_TYPE.MIN | PRICE_TYPE.MAX | typeof MINMAX

export type SalesCollectionIn = typeof ON_SALE | typeof NEW_COLLECTION | string

export type SalesCollectionOut = typeof SALES | typeof NO_SALES | string

export type Tag = { type: string }

export interface ColorOption {
	id: string
	label: string
	enLabel: string
}

export interface AppliedFiltersIn {
	selectedOrder: string | undefined
	selectedRange: RangePickerValue
	selectedLimitRange: RangePickerValue
	selectedFilters: UserFilter[]
	selectedCount: number
}

export type ApplyFiltersPayload = {
	location: string
	filteredResultsNumber: number
	color?: string[]
	size?: string[]
	sort?: string
	price?: FilterPrice
	collection?: SalesCollectionOut
	subfamilies?: string[]
}

export type PlpActionPayload = {
	label1: string
	columnsNumber: ColumnsNumber
	filtered: boolean
}

export type BasicFiltersPayload = {
	columnsNumber: ColumnsNumber
	filtered: boolean
}

export type OpenFiltersPayload = BasicFiltersPayload

export type CloseFiltersPayload = BasicFiltersPayload

export type DeleteTagFiltersPayload = {
	tag: string
}

export type TopNavigationPayload = {
	subfamilies: string
}

export type PlpEndPayload = BasicFiltersPayload

export type GenericPayload =
	| PlpActionPayload
	| OpenFiltersPayload
	| CloseFiltersPayload
	| ApplyFiltersPayload
	| DeleteTagFiltersPayload
	| TopNavigationPayload
	| PlpEndPayload
	| undefined

export enum PlpGenericEvents {
	PLP_ACTION = 'plp_action',
	OPEN_FILTERS = 'open_filters',
	CLOSE_FILTERS = 'close_filters',
	NO_RESULTS_FILTERS = 'no_results_filters',
	DELETE_FILTERS = 'delete_filters',
	APPLY_FILTERS = 'apply_filters',
	DELETE_TAG_FILTERS = 'delete_tag_filters',
	TOP_NAVIGATION = 'top_navigation',
	PLP_END = 'plp_end',
}

export enum PlpActionEvents {
	CHANGE_LAYOUT_VIEW = 'change_layout_view',
	SCROLL = 'scroll',
}

export enum TopNavigationType {
	IMAGE = 'image',
	TEXT = 'text',
}

export interface TopNavigationArgs {
	subfamilies: string
	type: TopNavigationType
}
