import {
	type FilterInfo,
	FilterType,
	type FiltersData,
	type FiltersItemData,
	type RangePickerValue,
	type UseFiltersSlotItem,
	type UserFilter,
} from 'product-list/types/Filters'
import type {
	OrderMethod,
	ProductListGroup,
} from 'product-list/types/ProductsList'

import { ALL, ALL_PRICES, DEFAULT_ORDER, ORDER_METHODS } from './constants'
import { filterItems } from './filterItems'

export function getFilterId(filter: FilterInfo) {
	if (filter.type === FilterType.generic) {
		return `${filter.type}-${filter.id}`
	}
	return filter.type
}

export function catalogItems(
	filters: FiltersData,
	catalogGroups: ProductListGroup[]
): FiltersItemData[] {
	let recommendedIndex = 0
	const itemIndexes: Map<string, number> = new Map() // <slotId, index>
	for (const group of catalogGroups) {
		for (const item of group.items) {
			itemIndexes.set(item.id, recommendedIndex)
			recommendedIndex += 1
		}
	}
	return Object.entries(filters.items).flatMap(([slotId, item]) => {
		const foundIdx = itemIndexes.get(slotId)
		const foundGeneric = filters.generic?.[item.productId]
		if (foundIdx === undefined) {
			return []
		}
		return {
			...item,
			...foundGeneric,
			id: slotId,
			index: foundIdx,
		}
	})
}

export function getRangePriceLimits(list: UseFiltersSlotItem[]) {
	return list.reduce(
		(previousRange, item) => {
			const currentRange = { ...previousRange }
			if (item.price < currentRange.min) {
				currentRange.min = Math.floor(item.price)
			}
			if (item.price > currentRange.max) {
				currentRange.max = Math.ceil(item.price)
			}
			return currentRange
		},
		{
			min: list?.[0]?.price || 0,
			max: list?.[0]?.price || 0,
		}
	)
}

export function clearSelection(filtersData: UserFilter[]): UserFilter[] {
	return filtersData.map((filter) => ({
		...filter,
		selection: filter.multipleChoice ? [] : [ALL],
	}))
}

export function getHasSelection(
	selectedFilters: UserFilter[],
	selectedOrder: string,
	selectedRange?: RangePickerValue,
	selectedLimitRange?: RangePickerValue
) {
	const isDefault = selectedOrder !== DEFAULT_ORDER
	const isUserRangeSelection =
		selectedRange?.min !== selectedLimitRange?.min ||
		selectedRange?.max !== selectedLimitRange?.max
	return (
		!!selectedFilters
			.map((filter) =>
				filter.selection
					.filter((selected) => selected !== ALL)
					.map((option) => option)
			)
			.flat().length ||
		isDefault ||
		isUserRangeSelection
	)
}

export function filteredItemsOnApply(
	originalItems: UseFiltersSlotItem[],
	selectedFilters: UserFilter[],
	selectedOrder: OrderMethod,
	selectedRange?: RangePickerValue
): UseFiltersSlotItem[] {
	return filterItems(
		selectedFilters,
		originalItems,
		selectedRange ?? ALL_PRICES,
		ORDER_METHODS[selectedOrder]
	)
}

export function normalizeRange(value: RangePickerValue) {
	return {
		min: Math.floor(value.min),
		max: Math.ceil(value.max),
	}
}

export function countActiveFilters(
	selectedFilters: UserFilter[],
	selectedRange: RangePickerValue | undefined,
	selectedLimitRange: RangePickerValue | undefined
) {
	let result = 0

	// Sum user filters
	result += selectedFilters.reduce((acc, filter) => {
		// Omit radio button filters
		if (!filter.multipleChoice) {
			return acc
		}
		// Omit filters with no selection
		const selections = filter.selection.filter(
			(selection) => !selection.includes('*')
		)
		return acc + selections.length
	}, 0)

	// Sum range filters
	if (
		selectedRange &&
		selectedLimitRange &&
		(selectedLimitRange.min !== selectedRange.min ||
			selectedLimitRange.max !== selectedRange.max)
	) {
		result += 1
	}

	return result
}

export function deepClone<T extends object>(obj: T): T {
	return JSON.parse(JSON.stringify(obj))
}
