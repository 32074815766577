import { useAbTest } from 'ab-test/hooks/useAbTest/useAbTest'
import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useClientFetcher } from 'fetch-toolkit/client/useClientFetcher/useClientFetcher'
import { Domains } from 'monitoring/constants/domains'
import { useSession } from 'session/src/hooks/useSession'
import useSWRImmutable from 'swr/immutable'
import { AbTestKeys } from 'types/constants/AbTestKeys'
import type { SegmentsResponse } from 'types/segmentation'
import { env } from 'utils/envManager'

const SEGMENTATION_TIMEOUT = 2000

export function useHandleSegmentation() {
	const { externalId } = useSession()

	const { clientFetcher } = useClientFetcher()

	const { enabled: allowSegmentation } = useFeatureFlag(
		featureFlagKeys.PlpSegmentation
	)

	const abTestDecision = useAbTest({
		key: AbTestKeys.PlpFirstFourSegmentation,
		defaultDecision: { enabled: false },
		preventRequestSDK: !externalId, // Prevents gets Optimizely SDK when no sessions is available
	})

	const fetcher = (url: string): Promise<SegmentsResponse | null> =>
		clientFetcher({
			url: new URL(url),
			requestConfig: {
				method: 'GET',
				timeout: SEGMENTATION_TIMEOUT,
				revalidate: 0,
			},
			errorConfig: {
				domain: Domains.PLP,
				message: 'Failed to fetch get segmentation',
			},
		})

	const { data, error } = useSWRImmutable<SegmentsResponse | null>(
		allowSegmentation && abTestDecision?.enabled && externalId
			? new URL(
					`${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-product-lists/v1/channels/shop/customers/${externalId}/segments`
				).href
			: null,
		fetcher,
		{}
	)

	if (data && !error) {
		return data.segments.product
	}

	return {}
}
