import type { View } from 'product-list/types/View'
import {
	getFromSessionStorage,
	removeFromSessionStorage,
	setToSessionStorage,
} from 'utils/webStorage/sessionStorage'

export type ProductListState = {
	slotId: string
	count: number
	view: View
}

const getProductListStateStorageKey = (name: string) => `product-list-${name}`

export const getProductListState = (
	name: string
): ProductListState | undefined => {
	const key = getProductListStateStorageKey(name)
	return getFromSessionStorage<ProductListState>(key) ?? undefined
}

export function storeProductListState(name: string, state: ProductListState) {
	const key = getProductListStateStorageKey(name)
	setToSessionStorage(key, state)
}

export function clearProductListState(name: string) {
	const key = getProductListStateStorageKey(name)
	removeFromSessionStorage(key)
}
