'use client'

import { InspirationalClientLink } from 'fukku/Button/ClientLink/Inspirational/index'
import {
	BUTTON_INSPIRATIONAL_STATE,
	LINK_SIZE,
} from 'fukku/Button/Inspirational/constants'
import { CATALOG_TITLE_ID } from 'plp/constants/catalogConfig'
import { dataTestIds } from 'plp/constants/dataTestIds'
import { usePlpProductListProvider } from 'plp/context/PlpProductListProvider/usePlpProductListProvider'
import { PlpGenericEvents, TopNavigationType } from 'plp/types/Analytics'
import type { SubSection } from 'plp/types/SubSections'
import { PRODUCT_LIST_QUERY_PARAMS } from 'product-list/constants/queryParams'
import { env } from 'utils/envManager'

import { EventElement } from '../Analytics/EventElement'

import styles from './SubSections.module.scss'

type Props = {
	data: SubSection[]
}

const ALL_ITEM = 0

export function SubSections({ data }: Props) {
	const { defaultView, currentView } = usePlpProductListProvider()

	// Omit the default view to avoid adding a query string with that view
	const selectedView = defaultView !== currentView ? currentView : undefined
	const parentCatalogId = data[ALL_ITEM]?.catalogId
	return (
		<nav className={styles.nav} aria-labelledby={CATALOG_TITLE_ID}>
			<ul
				className={styles.list}
				data-testid={dataTestIds.plpProductsListSubfamily}
			>
				{data.map((item) => {
					const { label, linkId, url, active } = item

					if (!url) {
						return null
					}

					const urlWithQueryParams = new URL(
						url,
						env.NEXT_PUBLIC_CURRENT_ORIGIN
					)
					if (selectedView) {
						urlWithQueryParams.searchParams.append(
							PRODUCT_LIST_QUERY_PARAMS.view,
							selectedView
						)
					}

					return (
						<li
							key={linkId}
							data-testid={dataTestIds.plpProductsListSubfamilyItem(
								label,
								active
							)}
						>
							<EventElement
								eventName={PlpGenericEvents.TOP_NAVIGATION}
								eventData={{
									type: TopNavigationType.TEXT,
									subfamilies: label,
								}}
							>
								<InspirationalClientLink
									size={LINK_SIZE.S}
									href={urlWithQueryParams.href}
									sectionId={parentCatalogId}
									state={
										active
											? BUTTON_INSPIRATIONAL_STATE.ACTIVE
											: BUTTON_INSPIRATIONAL_STATE.UNSELECTED
									}
								>
									{label}
								</InspirationalClientLink>
							</EventElement>
						</li>
					)
				})}
			</ul>
		</nav>
	)
}
