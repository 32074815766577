import type { View } from 'product-list/types/View'

export const dataTestIds = {
	plpProductsList: (catalogSeo: string) => `plp.products.list.${catalogSeo}`,
	plpClientGridList: 'plp.client.grid.list',
	plpServerGridList: 'plp.server.grid.list',
	plpProductsListSubfamily: 'plp.products.list.subfamily',
	plpProductsListSubfamilyItem: (label: string, isActive: boolean) =>
		`plp.products.list.subfamily.${label.split('_').pop()}${isActive ? '_select' : ''}`,
	plpProductCard: 'plp.product',
	plpSlot: 'plp.slot',
	plpSlotClient: 'plp.slot.client',
	plpSlotServer: 'plp.slot.server',
	plpFilterPanel: (isMobile: boolean) =>
		`plp.filters.${isMobile ? 'mobile' : 'desktop'}.panel`,
	plpFilterMobileType: (type: string) => `plp.filters.mobile.panel.${type}`,
	plpFilterDesktopCloseButton: 'plp.filters.desktop.panel.close',
	plpFilterDesktopItemsCount: 'plp.filters.desktop.panel.items',
	plpFilterDesktopGroup: (isMobile: boolean) =>
		`plp.filters.${isMobile ? 'mobile' : 'desktop'}.panel.group`,
	plpFilterDesktopItem: (label: string, isMobile: boolean) =>
		`plp.filters.${isMobile ? 'mobile' : 'desktop'}.panel.item-${label.split('_').pop()}`,
	plpFilterDesktopRangeLabel: (type: string) => `plp.rangePicker.label-${type}`,
	plpQuickFilters: (type: string) => `plp.quickfilters.${type}`,
	plpQuickFiltersOptions: (type: string) => `plp.quickfilters.${type}.options`,
	plpQuickFiltersOption: (type: string, optionId: string | number) =>
		`plp.quickfilters.${type}.option-${optionId}`,
	plpQuickFiltersTitle: (type: string) => `plp.quickfilters.${type}.title`,
	plpQuickFiltersDescription: (type: string, descriptionId: string | number) =>
		`plp.quickfilters.${type}.description-${descriptionId}`,
	plpQuickFiltersHorizontalScroll: (type: string) =>
		`plp.quickfilters.${type}.horizontalScroll`,
	plpFilterApplyButton: 'plp.filters.apply.button',
	plpFilterClearButton: 'plp.filters.clear.button',
	productListView: (view: View) => `productList.view.${view}`,
	productListViewSelected: `productList.view.selected`,
	productListViewUnselected: `productList.view.unselected`,
	gridView: (view: View) => `productList.grid.${view}`,
}
