'use client'

import { brandPath } from 'country-configuration/utils/brands/brandPath'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { type ClientFeatureFlagLinkProps } from '../../components/featureFlagLink/client'
import { url } from '../constants'
import type { SearchLinkDependencies } from '../types'
import { searchUrl } from '../utils/searchUrl'

type SearchResultsClientLinkProps = Omit<
	ClientFeatureFlagLinkProps,
	'featureFlagKey'
> &
	SearchLinkDependencies

export function SearchClientLink({
	brandId,
	children,
	searchTerm,
	...linkProps
}: SearchResultsClientLinkProps) {
	const { country } = useMasterData()
	const { t } = useLabels()

	const urlParams = {
		brandPath: brandPath({
			brandId,
			brands: country.brands,
			translate: t,
		}),
		pathNameBase: url,
		searchTerm,
	}

	const href = searchUrl(urlParams)

	const props: Omit<ClientLinkProps, 'prefetch'> = {
		...linkProps,
		children,
		href,
	}

	return <ClientLink {...props} />
}
