'use client'

import { useProductCardCrossSellingSettings } from 'cross-selling/hooks/useProductCardCrossSellingSettings/useProductCardCrossSellingSettings'
import type { CrossSellingClientProps } from 'cross-selling/types'
import { getProductCardImagesSizes } from 'cross-selling/utils/getProductCardImagesSizes/getProductCardImagesSizes'
import { mergeProductData } from 'cross-selling/utils/mergeProductData'
import { Carousel } from 'fukku/Carousel/components/Carousel'
import { CarouselVariants } from 'fukku/Carousel/types'
import { ClientProductCard } from 'product-card/components/client'
import { ProductCardConsumerLayoutProvider } from 'product-card/context/ProductCardConsumerLayoutContext'
import { ProductCardImagesSizesProvider } from 'product-card/context/ProductCardImagesSizesContext'
import { ProductDataProvider } from 'product-card/context/ProductDataContext'

export const ClientCrossSelling = ({
	crossSellingItems,
	title,
	variant = CarouselVariants.VARIANT2,
	imageSortCriteria,
	productsData,
	className,
	location,
	productListId,
	hasFavoriteButton,
	hasSizeSheetButton = true,
	'data-testid': dataTestId,
	showDiscount,
}: CrossSellingClientProps) => {
	const previouslyFetchedProducts =
		productsData && mergeProductData({ crossSellingItems, productsData })

	const productCardImagesSizes = getProductCardImagesSizes()

	const productCardCrossSellingSettings = useProductCardCrossSellingSettings({
		hasSizeSheetButton,
		hasFavoriteButton,
	})

	return (
		<ProductCardConsumerLayoutProvider
			settings={productCardCrossSellingSettings}
		>
			<ProductCardImagesSizesProvider
				defaultImageSizes={{ regular: productCardImagesSizes[variant] }}
			>
				<Carousel
					className={className}
					dataTestId={dataTestId}
					title={title}
					variant={variant}
				>
					{previouslyFetchedProducts
						? previouslyFetchedProducts.map(
								(product) =>
									product.productData && (
										<div
											key={`${product.productId}-${product.colorId}-${product.lookId}`}
										>
											<ProductDataProvider
												key={`${product.productId}-${product.colorId}-${product.lookId}`}
												initialProductData={product.productData}
											>
												<ClientProductCard
													productId={product.productId}
													colorId={product.colorId}
													lookId={product.lookId}
													imageSortCriteria={imageSortCriteria}
													dataTestId='crossSelling'
													location={location}
													productListId={productListId}
													showDiscount={showDiscount}
													noDraggable
												/>
											</ProductDataProvider>
										</div>
									)
							)
						: crossSellingItems.map((product) => (
								<div
									key={`${product.productId}-${product.colorId}-${product.lookId}`}
								>
									<ClientProductCard
										productId={product.productId}
										colorId={product.colorId}
										lookId={product.lookId}
										imageSortCriteria={imageSortCriteria}
										dataTestId='crossSelling'
										location={location}
										productListId={productListId}
										showDiscount={showDiscount}
										noDraggable
									/>
								</div>
							))}
				</Carousel>
			</ProductCardImagesSizesProvider>
		</ProductCardConsumerLayoutProvider>
	)
}
