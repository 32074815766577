import { Alert } from 'order-status-banner/types/alert/alert'

export interface Order {
	id: string
	status: OrderStatusWithIcon
	alert?: Alert
}

export enum OrderStatusWithIcon {
	CREATED = 'created',
	IN_PREPARATION = 'in_preparation',
	READY = 'ready',
	SHIPPED = 'shipped',
	ON_DELIVERY = 'on_delivery',
	ON_INTERNATIONAL_TRANSIT = 'on_international_transit',
	DELIVERED = 'delivered',
	IN_STORE = 'in_store',
	IN_DROPPOINT = 'in_droppoint',
}

export enum OrderStatusWithoutIcon {
	CANCELLED = 'cancelled',
	REJECTED = 'rejected',
	REFUNDED = 'refunded',
	NOT_DELIVERED = 'not_delivered',
	DENIED = 'denied',
	NOT_AVAILABLE = 'not_available',
}
