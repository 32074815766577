'use client'

import { useNavigationBrand } from 'hooks/useNavigationBrand/useNavigationBrand'
import { IconSearchL } from 'icons/components/IconSearchL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { SearchClientLink } from 'links/search/client'

import { TEST_IDS } from '../../constants/landing'

import styles from './HeaderSearchLink.module.scss'

type HeaderSearchBoxProps = {
	labelClassName: string
	iconClassName: string
}

export function HeaderSearchLink({
	labelClassName,
	iconClassName,
}: HeaderSearchBoxProps): React.ReactNode {
	const { t } = useLabels()
	const { brand } = useNavigationBrand()

	return (
		brand && (
			<SearchClientLink
				brandId={brand.id}
				className={styles.link}
				data-testid={TEST_IDS.HEADER_SEARCH_LINK}
				aria-label={t('accessibility.header.search.icon')}
			>
				<IconSearchL className={iconClassName} />
				<span className={labelClassName}>{t('header.search.text')}</span>
			</SearchClientLink>
		)
	)
}
