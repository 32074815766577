type SerachResultsUrlParams = {
	brandPath: string
	pathNameBase: string
	searchTerm?: string
}

export const searchUrl = ({
	brandPath,
	pathNameBase,
	searchTerm,
}: SerachResultsUrlParams) => {
	if (!searchTerm) {
		return `${pathNameBase}${brandPath}`
	}

	const searchParams = new URLSearchParams({ q: searchTerm })

	return `${pathNameBase}${brandPath}?${searchParams}`
}
