'use client'

import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { dataTestIds } from 'plp/constants/dataTestIds'
import { TopNavigationType } from 'plp/types/Analytics'
import type { BannerItem } from 'plp/types/Banner'
import { EventName } from 'plp/types/eventsObserver'
import { eventsObserver } from 'plp/utils/eventsObserver'
import { getSubsectionUrl } from 'plp/utils/getSubsectionUrl/getSubsectionUrl'
import { useRef } from 'react'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import styles from './Option.module.scss'

interface Props {
	item: BannerItem
	isActive: boolean
	indexItem: number
	showWithFewItemsRatio: boolean
}

export function Option({
	item,
	isActive,
	indexItem,
	showWithFewItemsRatio,
}: Props) {
	const searchParams = useSearchParams()
	const ref = useRef<HTMLDivElement>(null)
	const imageRef = useRef<HTMLImageElement>(null)

	const url = getSubsectionUrl(item.url, searchParams, item.collection)

	return (
		<div
			key={item.linkId}
			className={cx(
				styles.item,
				isActive && styles.active,
				showWithFewItemsRatio ? styles.fewItemsRatio : styles.manyItemsRatio
			)}
			ref={ref}
			data-testid={dataTestIds.plpQuickFiltersOption('squared', indexItem)}
		>
			<ClientLink
				href={`${url.pathname}${url.search}`}
				isLocalizedPath
				onClick={() =>
					eventsObserver.dispatch(EventName.TOP_NAVIGATION, {
						subfamilies: item.title.toLowerCase(),
						type: TopNavigationType.IMAGE,
					})
				}
			>
				<Image
					src={item.image}
					alt=''
					className={styles.image}
					fill
					sizes={ref?.current ? `${ref.current.clientWidth}px` : '100px'}
					ref={imageRef}
				/>

				<div className={styles.text}>
					<span
						className={cx(styles.title)}
						data-testid={dataTestIds.plpQuickFiltersTitle('squared')}
					>
						{item.title}
					</span>
					{item.descriptions?.map((description, indexDescription) => (
						<div
							key={description}
							className={cx(
								styles.description,
								indexDescription === 0 && styles.separator
							)}
							data-testid={dataTestIds.plpQuickFiltersDescription(
								'squared',
								indexDescription
							)}
						>
							{description}
						</div>
					))}
				</div>
			</ClientLink>
		</div>
	)
}
