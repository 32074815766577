import {
	SearchOrigin,
	sendSearchEvent,
} from 'analytics/events/recommended/search/search'
import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useSearchLink } from 'links/search/hook/useSearchLink'
import type { SearchLinkDependencies } from 'links/search/types'
import { useEffect } from 'react'

import {
	SEARCHLOCALSTORAGEKEYS,
	getSearchLocalStorage,
	setSearchLocalStorage,
} from '../utils/searchLocalStorage'
import { useSearchByProductReference } from './useSearchByProductReference'

const handleSearchHistory = (keyword: string) => {
	const numberStoredKeywords = 5
	const searchedKeywords: string[] = getSearchLocalStorage(
		SEARCHLOCALSTORAGEKEYS.SEARCHKEYWORDS
	)
	const storedKeywords: string[] =
		searchedKeywords?.length > 0 ? Array.from(searchedKeywords) : []

	if (storedKeywords?.every((key) => key !== keyword)) {
		storedKeywords.unshift(keyword)
		storedKeywords.splice(numberStoredKeywords)
	}

	setSearchLocalStorage('searchedKeywords', storedKeywords)
}

type UseSearchConfig = Required<SearchLinkDependencies> & {
	origin: SearchOrigin
	callbackOnSearch(): void
}

export const useSearch = ({
	brandId,
	origin,
	searchTerm,
	callbackOnSearch,
}: UseSearchConfig) => {
	const href = useSearchLink({
		brandId,
		searchTerm,
	})
	const { navigateToPdp, isProductId, errorOnNavigate } =
		useSearchByProductReference({
			callbackOnSearch,
		})
	const { navigate } = useNavigation()

	const navigateToSearchResults = () => {
		if (href) {
			sendSearchEvent({
				search_term: searchTerm,
				type: origin,
			})
			handleSearchHistory(searchTerm)
			callbackOnSearch()
			navigate(href)
		}
	}

	useEffect(() => {
		if (errorOnNavigate) {
			navigateToSearchResults()
		}
	}, [errorOnNavigate])

	return () => {
		if (isProductId(searchTerm)) {
			return navigateToPdp(searchTerm)
		}

		return navigateToSearchResults()
	}
}
