'use client'

import { useHeaderState } from 'hooks/useHeaderContext/useHeaderState'
import { HeaderSearchBox } from 'search/components/HeaderSearchBoxLegacy/HeaderSearchBox'

import { UserToolsItem } from '../Item/UserToolsItem'

import iconsStyles from '../Icons.module.scss'

export function SearchTool({
	shouldRender = true,
}: {
	shouldRender?: boolean
}) {
	const { isSearchOpen } = useHeaderState()

	return (
		<UserToolsItem shouldRender={shouldRender} fill={isSearchOpen}>
			<HeaderSearchBox
				iconClassName={iconsStyles.icon}
				labelClassName={iconsStyles.text}
			/>
		</UserToolsItem>
	)
}
