import type { UseFiltersSlotItem } from '../../types/Filters'
import type { OrderMethod } from '../../types/ProductsList'

export const ALL = '*'
export const RECOMMENDED = 'recommended'
export const ASC = 'asc'
export const DESC = 'desc'
export const SERIALIZED_FIELDS_SEPARATOR = '.'
export const SERIALIZED_SELECTION_SEPARATOR = '~'
export const SERIALIZED_SELECTIONS_SEPARATOR = '_'
export const SERIALIZED_DECIMALS = 2
export const ON_SALE = 'onSale'
export const NEW_COLLECTION = 'newCollection'
export const ALL_PRICES = { min: 0, max: Number.MAX_SAFE_INTEGER }

export const ORDER_METHODS: Record<
	OrderMethod,
	(a: UseFiltersSlotItem, b: UseFiltersSlotItem) => number
> = {
	[RECOMMENDED]: (a, b) => a.index - b.index,
	[ASC]: (a, b) => a.price - b.price,
	[DESC]: (a, b) => b.price - a.price,
}

export const DEFAULT_ORDER = RECOMMENDED

export const ORDER_OPTIONS = [
	{
		id: RECOMMENDED,
		label: 'product.filters.orderBy.recommended.radiobutton',
	},
	{
		id: ASC,
		label: 'product.filters.orderBy.ascendent.radiobutton',
	},
	{
		id: DESC,
		label: 'product.filters.orderBy.descendent.radiobutton',
	},
]
