import {
	FilterType,
	type RangePickerValue,
	type UseFiltersSlotItem,
	type UserFilter,
} from 'product-list/types/Filters'

import { ALL, NEW_COLLECTION, ON_SALE } from './constants'
import { getIdValue, getOptionId } from './mappers'

/**
 * The 'product' meets the criteria based on the generic filter
 */
function meetsConditionGeneric(
	product: UseFiltersSlotItem,
	currentFilter: UserFilter
): boolean {
	if (currentFilter.type !== FilterType.generic) {
		return false
	}
	if (!currentFilter.multipleChoice && currentFilter.selection?.[0] === ALL) {
		return true
	}
	return currentFilter.selection.some((value) =>
		product[currentFilter.id]?.includes(getIdValue(value))
	)
}

/**
 *  The 'product' meets the condition that they have the color group of selected color groups
 */
function meetsConditionColorGroups(
	product: UseFiltersSlotItem,
	currentFilter: UserFilter
): boolean {
	const colorOptionId = getOptionId(currentFilter.type, product.colorGroup)
	return currentFilter.selection.includes(colorOptionId)
}

/**
 * The 'product' meets the condition that have some size of selected sizes
 */
function meetsConditionSizes(
	product: UseFiltersSlotItem,
	currentFilter: UserFilter
): boolean {
	return currentFilter.selection.some((size) =>
		product.sizes.includes(getIdValue(size))
	)
}

/**
 * The 'product' has a price between min and max values
 */
function meetsConditionRangePrice(
	product: UseFiltersSlotItem,
	selectedRange?: RangePickerValue
): boolean {
	return (
		!selectedRange ||
		(product.price <= selectedRange.max && product.price >= selectedRange.min)
	)
}

/**
 * The price of 'product' is on sale or is a new collection
 */
function meetsConditionPrice(
	item: UseFiltersSlotItem,
	currentFilter: UserFilter,
	selectedRange?: RangePickerValue
): boolean {
	const firstSelectedValue = currentFilter.selection?.[0]
	if (!firstSelectedValue || firstSelectedValue === ALL) {
		return meetsConditionRangePrice(item, selectedRange)
	}
	const isOnSaleProduct =
		firstSelectedValue === getOptionId(FilterType.price, ON_SALE) && item.onSale
	const isNewCollectionProduct =
		firstSelectedValue === getOptionId(FilterType.price, NEW_COLLECTION) &&
		!item.onSale
	return (
		(isOnSaleProduct || isNewCollectionProduct) &&
		meetsConditionRangePrice(item, selectedRange)
	)
}

type MeetsConditionMethod = (
	item: UseFiltersSlotItem,
	currentFilter: UserFilter,
	selectedRange?: RangePickerValue
) => boolean

const conditionMethods: Record<FilterType, MeetsConditionMethod> = {
	[FilterType.generic]: meetsConditionGeneric,
	[FilterType.colorGroups]: meetsConditionColorGroups,
	[FilterType.sizes]: meetsConditionSizes,
	[FilterType.price]: meetsConditionPrice,
	[FilterType.order]: () => true,
}

/**
 * The 'product' meets conditions based on user selection criteria
 */
function meetsCondition(
	product: UseFiltersSlotItem,
	currentFilter: UserFilter,
	selectedRange?: RangePickerValue
): boolean {
	if (
		!currentFilter.selection.length &&
		currentFilter.type !== FilterType.price
	) {
		return true
	}
	const conditionMethod = conditionMethods[currentFilter.type]
	return conditionMethod(product, currentFilter, selectedRange)
}

/**
 * The 'product' meets with all conditions
 */
function meetsFilter(
	product: UseFiltersSlotItem,
	userFilters: UserFilter[],
	selectedRange: RangePickerValue
) {
	// Not found any condition that not meets
	return !userFilters
		.map((currentFilter) =>
			meetsCondition(product, currentFilter, selectedRange)
		)
		.some((condition) => !condition)
}

/**
 * A least one of the 'product' meets with all conditions
 */
export function atLeastOne(
	userFilters: UserFilter[],
	products: UseFiltersSlotItem[],
	selectedRange: RangePickerValue
): boolean {
	const filteredItems = products.find((item) =>
		meetsFilter(item, userFilters, selectedRange)
	)

	return !!filteredItems
}

/**
 * Get all 'products' that meet with all conditions from the user selected filters
 */
export function filterItems(
	userFilters: UserFilter[],
	products: UseFiltersSlotItem[],
	selectedRange: RangePickerValue,
	selectedOrder?: (a: UseFiltersSlotItem, b: UseFiltersSlotItem) => number
): UseFiltersSlotItem[] {
	const filteredItems = products.flatMap((item) =>
		meetsFilter(item, userFilters, selectedRange) ? item : []
	)
	if (!selectedOrder) {
		return filteredItems
	}
	return filteredItems.sort(selectedOrder)
}
