'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useCallback } from 'react'
import useSWRImmutable from 'swr/immutable'

import { getFromClientFiltersData } from '../../services/getFromClientFiltersData'
import type { ProductListGroup } from '../../types/ProductsList'
import { type UseFiltersData, getFiltersData } from '../../utils/getFiltersData'

export interface UseGetFiltersDataParams {
	defaultFiltersData: UseFiltersData | undefined
	groups: ProductListGroup[]
	filtersOnClientEndpoint: string | undefined
}

/**
 * Hook for fetching filters data.
 *
 * @param {UseGetFiltersDataParams} params - An object containing the following properties:
 * @param {UseFiltersData | undefined} params.defaultOrder - Default filters data to use.
 * @param {OrderProductListGroup[]} params.groups - The product list group(s) to fetch the corresponding filters for.
 * @param {string | undefined} params.filtersOnClientEndpoint - If provided, fetches filters data directly from a specific client endpoint. Otherwise, uses default behavior.
 *
 * @returns {UseFiltersData | undefined} An object containing the filters data
 */
export const useGetFiltersData = ({
	defaultFiltersData,
	groups,
	filtersOnClientEndpoint,
}: UseGetFiltersDataParams) => {
	const { test, country, customDate } = useMasterData()
	const { languageISO: language } = country

	const loadFiltersData = useCallback(
		async (endpoint: string) => {
			const catalogFiltersData = await getFromClientFiltersData({
				language,
				customDate,
				test,
				endpoint,
			})
			return getFiltersData({
				serverData: catalogFiltersData,
				groups,
			})
		},
		[customDate, groups, language, test]
	)

	const shouldFetch = !!filtersOnClientEndpoint

	const { data, isLoading } = useSWRImmutable(
		shouldFetch ? filtersOnClientEndpoint : null,
		loadFiltersData
	)

	if (!shouldFetch || isLoading) {
		return defaultFiltersData
	}

	return data
}
