'use client'

import type { CarouselTitle, CarouselVariants } from 'fukku/Carousel/types'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import type { ProductImageType } from 'product/types'
import type { ProductLocations } from 'product/types/ProductLocations'

import { useCollaborativeRecommendations } from '../../../hooks/useCollaborativeRecommendations'
import type { CrossSellingServiceResponse } from '../../../types'
import { ClientCrossSelling } from '../../shared/CrossSelling/client/ClientCrossSelling'

export interface CollaborativeRecommendationsCrossSellingProps {
	dataTestId: string
	location: ProductLocations
	serverCollaborativeRecommendationsProducts: CrossSellingServiceResponse[]
	title: string
	className?: string
	hasFavoriteButton?: boolean
	variant?: CarouselVariants
}

const imageSortCriteria: ProductImageType[] = ['B']

export function CollaborativeRecommendationsCrossSelling({
	dataTestId,
	location,
	serverCollaborativeRecommendationsProducts,
	title,
	className,
	hasFavoriteButton,
	variant,
}: CollaborativeRecommendationsCrossSellingProps): JSX.Element | boolean {
	const { doFetch, data: clientCollaborativeRecommendationsProducts } =
		useCollaborativeRecommendations()
	const { t } = useLabels()

	const collaborativeRecommendationsProducts = doFetch
		? clientCollaborativeRecommendationsProducts
		: serverCollaborativeRecommendationsProducts

	const titleProps: CarouselTitle = {
		content: t(title),
	}

	return (
		!!collaborativeRecommendationsProducts?.length && (
			<ClientCrossSelling
				crossSellingItems={collaborativeRecommendationsProducts}
				data-testid={dataTestId}
				imageSortCriteria={imageSortCriteria}
				title={titleProps}
				location={location}
				productListId={location}
				className={className}
				hasFavoriteButton={hasFavoriteButton}
				variant={variant}
			/>
		)
	)
}
