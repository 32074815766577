'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useFiltersSearchParams } from 'product-list/hooks/useFiltersSearchParams'
import {
	FilterId,
	FilterType,
	type RangePickerValue,
	type UseFiltersData,
	type UserFilter,
} from 'product-list/types/Filters'
import type {
	OrderMethod,
	ProductListGroup,
	ProductListParams,
} from 'product-list/types/ProductsList'
import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	createContext,
	useCallback,
	useMemo,
} from 'react'

import { DEFAULT_ORDER, ORDER_OPTIONS } from './constants'
import { getDisabledValues } from './disabledValues'
import { filterItems } from './filterItems'
import { rangeValueFrom, serialize } from './serializer/serializer'
import { useFiltersValue } from './useFiltersValue'
import { useGetFiltersData } from './useGetFiltersData'
import { countActiveFilters, getHasSelection } from './utils'

export interface ProductListFiltersContextType {
	selectedFilters: UserFilter[]
	orderFilter: UserFilter
	selectedOrder: string
	selectedRange: RangePickerValue | undefined
	selectedLimitRange: RangePickerValue | undefined
	selectedCount: number
	hasSelection: boolean
	disabledValues: string[]
	nAvailableFilters: number
	nActiveFilters: number
	setSelectedRange: (range: RangePickerValue) => void
	setSelectedOrder: Dispatch<SetStateAction<OrderMethod>>
	apply: () => void
	clear: () => void
	toggle: (type: string, value: string, id?: string) => void
	reset: () => void
}

interface ProductListFiltersProviderProps {
	defaultFiltersData: UseFiltersData | undefined
	defaultFilter: ProductListParams | undefined
	filterResultPathname: string
	groups: ProductListGroup[]
	filtersOnClientEndpoint?: string
	children?: ReactNode
}

const noop = () => {
	// Do nothing
}

const defaultContext: ProductListFiltersContextType = {
	selectedFilters: [],
	orderFilter: {
		id: FilterId.subfamilies,
		label: '',
		selection: [],
		choices: [],
		multipleChoice: false,
		options: [],
		type: FilterType.generic,
	},
	selectedOrder: '',
	selectedRange: undefined,
	selectedLimitRange: undefined,
	selectedCount: 0,
	hasSelection: false,
	disabledValues: [],
	nAvailableFilters: 0,
	nActiveFilters: 0,
	setSelectedOrder: noop,
	apply: noop,
	clear: noop,
	setSelectedRange: noop,
	toggle: noop,
	reset: noop,
}

export const ProductListFiltersContext =
	createContext<ProductListFiltersContextType>(defaultContext)

export const ProductListFiltersProvider = ({
	defaultFiltersData,
	defaultFilter,
	filterResultPathname,
	filtersOnClientEndpoint,
	groups,
	children,
}: ProductListFiltersProviderProps) => {
	const filtersData = useGetFiltersData({
		defaultFiltersData,
		filtersOnClientEndpoint,
		groups,
	})

	const originalItems = useMemo(
		() =>
			defaultFiltersData?.catalogItemsData ??
			filtersData?.catalogItemsData ??
			[],
		[defaultFiltersData?.catalogItemsData, filtersData?.catalogItemsData]
	)

	const defaultOrder = defaultFilter?.order ?? DEFAULT_ORDER

	const defaultRangeLimit = useMemo(
		() => defaultFiltersData?.range ?? filtersData?.range ?? { max: 0, min: 0 },
		[defaultFiltersData?.range, filtersData?.range]
	)

	const defaultRange = useMemo(
		() => (defaultFilter ? rangeValueFrom(defaultFilter.range) : undefined),
		[defaultFilter]
	)

	const {
		selectedFilters,
		selectedLimitRange,
		selectedOrder,
		selectedRange,
		clear,
		setSelectedRange,
		setSelectedOrder,
		toggle,
		reset,
	} = useFiltersValue({
		defaultFiltersData,
		defaultOrder,
		defaultRangeLimit,
		defaultRange,
		filtersData,
		originalItems,
	})

	const { t } = useLabels()
	const { push } = useFiltersSearchParams(filterResultPathname)

	const disabledValues = useMemo(
		() =>
			getDisabledValues(
				selectedFilters,
				originalItems,
				selectedRange,
				selectedLimitRange
			),
		[originalItems, selectedFilters, selectedLimitRange, selectedRange]
	)

	const selectedCount = useMemo(() => {
		const filteredItems = filterItems(
			selectedFilters,
			originalItems,
			selectedRange
		)
		return filteredItems.length
	}, [originalItems, selectedFilters, selectedRange])

	const nAvailableFilters = useMemo(
		() => filtersData?.filters.length ?? 0,
		[filtersData?.filters.length]
	)

	const nActiveFilters = useMemo(
		() =>
			defaultFiltersData
				? countActiveFilters(
						defaultFiltersData.filters,
						defaultRange,
						defaultFiltersData.range
					)
				: 0,
		[defaultFiltersData, defaultRange]
	)

	const orderFilter = useMemo<UserFilter>(
		() => ({
			type: FilterType.order,
			label: 'product.filters.orderBy.title',
			selection: [selectedOrder],
			multipleChoice: false,
			options: ORDER_OPTIONS.map((option) => ({
				...option,
				label: t(option.label),
			})),
		}),
		[selectedOrder, t]
	)

	const apply = useCallback(() => {
		const serializedParams = serialize({
			selectedFilters,
			selectedOrder,
			selectedRange,
			selectedLimitRange,
		})
		push(serializedParams)
	}, [push, selectedFilters, selectedLimitRange, selectedOrder, selectedRange])

	const result = useMemo<ProductListFiltersContextType>(() => {
		const hasSelection = getHasSelection(
			selectedFilters,
			selectedOrder,
			selectedRange,
			selectedLimitRange
		)
		return {
			defaultRange,
			disabledValues,
			hasSelection,
			nAvailableFilters,
			nActiveFilters,
			orderFilter,
			selectedCount,
			selectedFilters,
			selectedLimitRange,
			selectedOrder,
			selectedRange,
			apply,
			setSelectedOrder,
			toggle,
			reset,
			clear,
			setSelectedRange,
		}
	}, [
		apply,
		clear,
		defaultRange,
		disabledValues,
		nActiveFilters,
		nAvailableFilters,
		orderFilter,
		reset,
		selectedCount,
		selectedFilters,
		selectedLimitRange,
		selectedOrder,
		selectedRange,
		setSelectedOrder,
		setSelectedRange,
		toggle,
	])

	return (
		<ProductListFiltersContext.Provider value={result}>
			{children}
		</ProductListFiltersContext.Provider>
	)
}
