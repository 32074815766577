import type {
	FilterInfo,
	FiltersSearchParams,
	SerializedParams,
	UserFilter,
} from 'product-list/types/Filters'

import {
	ALL,
	SERIALIZED_DECIMALS,
	SERIALIZED_FIELDS_SEPARATOR,
	SERIALIZED_SELECTIONS_SEPARATOR,
	SERIALIZED_SELECTION_SEPARATOR,
} from '../constants'
import { getFilterId } from '../utils'

export function serializeDynamicFilter(filter: FilterInfo) {
	if (filter.id) {
		return `${filter.type}${SERIALIZED_SELECTION_SEPARATOR}${filter.id}`
	}
	return filter.type
}

export const serializeUserFilter = (
	filterId: string,
	selection: UserFilter['selection']
) =>
	`${filterId}${SERIALIZED_SELECTION_SEPARATOR}${selection
		.map((option) => option.split('_')[1])
		.join(SERIALIZED_SELECTIONS_SEPARATOR)}`

/**
 * Serialize params from filters to a string like:
 * 'range=13244.00-32990.00&order=asc&filters=colorGroups~4_2.sizes~XL_XXL'
 */
export function serialize({
	selectedFilters,
	selectedOrder,
	selectedRange,
	selectedLimitRange,
}: SerializedParams) {
	const { min, max } = selectedRange || { min: 0, max: 0 }
	const filters = selectedFilters
		.filter((filter) => filter.selection.length)
		.flatMap((filter) =>
			filter.selection.length && filter.selection[0] !== ALL
				? serializeUserFilter(getFilterId(filter), filter.selection)
				: []
		)
		.join(SERIALIZED_FIELDS_SEPARATOR)
	const hasDifferentRange =
		selectedRange?.min !== selectedLimitRange?.min ||
		selectedRange?.max !== selectedLimitRange?.max
	const range = [
		!!selectedRange,
		!!selectedLimitRange,
		hasDifferentRange,
	].every((condition) => condition)
		? `${min.toFixed(SERIALIZED_DECIMALS)}-${max.toFixed(SERIALIZED_DECIMALS)}`
		: undefined
	return {
		range,
		filters,
		order: selectedOrder,
	}
}

/**
 * De-serialize params from a string like:
 * 'range=13244.00-32990.00&order=asc&filters=colorGroups~4_2.sizes~XL_XXL'
 *                                            ^ filter name  ^ filters separator
 * 'range=13244.00-32990.00&order=asc&filters=colorGroups~4_2.sizes~XL_XXL'
 *                                                                    ^ filters values separator
 */
export function deserialize(serialized?: FiltersSearchParams) {
	if (serialized) {
		const rangeValues = serialized.range?.split('-')
		const [min, max] = rangeValues ?? []
		const range = rangeValues
			? {
					min: Number(min),
					max: Number(max),
				}
			: undefined
		const order = serialized.order
		const options = serialized.filters
			? serialized.filters.split(SERIALIZED_FIELDS_SEPARATOR).map((option) => {
					const [id, selection] = option.split(SERIALIZED_SELECTION_SEPARATOR)
					/** de-serializing each option on filters */
					return {
						id,
						selection: selection
							.split(SERIALIZED_SELECTIONS_SEPARATOR)
							.map((selectionOption) => `${id}_${selectionOption}`),
					}
				})
			: []
		return { range, order, options }
	}
	return null
}
export function rangeValueFrom(rangeSerialized?: string) {
	if (!rangeSerialized) {
		return undefined
	}
	const [min, max] = rangeSerialized.split('-')
	return {
		min: Number(min),
		max: Number(max),
	}
}
