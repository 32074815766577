'use client'

import { dataTestIds } from 'plp/constants/dataTestIds'
import type { BannerGroup } from 'plp/types/Banner'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import Options from './Options/Options'
import Title from './Title/Title'

import styles from './Rounded.module.scss'

interface Props {
	data: BannerGroup
	language?: string
	catalogId: string
	collection?: string
}

export function Rounded({ data, language, catalogId, collection }: Props) {
	const { isLargeOrGreater } = useResponsive()

	return (
		<div
			className={isLargeOrGreater ? styles.container : ''}
			data-testid={dataTestIds.plpQuickFilters('rounded')}
		>
			{!!data.extra && !!language && (
				<Title data={data.extra} language={language} />
			)}
			<Options
				data={data.navigations}
				catalogId={catalogId}
				collection={collection}
			/>
		</div>
	)
}
