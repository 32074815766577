'use client'

import type { FetcherProps } from 'fetch-toolkit/types'
import { useLog } from 'monitoring/sendLog/client/useLog'
import { useCallback } from 'react'
import { StatusCode } from 'types/statusCodes'

const DEFAULT_TIMEOUT = 3000

export function useClientFetcher() {
	const { sendLog } = useLog()

	const clientFetcher = useCallback(
		<T>({
			url,
			searchParams,
			requestConfig,
			errorConfig,
		}: FetcherProps): Promise<T | null> => {
			const {
				method,
				timeout = DEFAULT_TIMEOUT,
				...fetchConfig
			} = requestConfig

			const { domain, message, custom } = errorConfig

			const controller = new AbortController()
			let timeoutId: number | undefined

			if (timeout) {
				timeoutId = window.setTimeout(() => controller.abort(), timeout)
			}

			// Add search params
			if (searchParams) {
				url.search = searchParams.toString()
			}

			// eslint-disable-next-line mng/fetch-always-revalidate-with-zero
			return fetch(url, {
				...fetchConfig,
				method,
				signal: controller.signal,
			})
				.then((response) => {
					if (timeoutId) {
						clearTimeout(timeoutId)
					}

					if (!response.ok) {
						if (response.status === StatusCode.NOT_FOUND) {
							return {
								data: null,
								status: response.status,
								headers: response.headers,
								ok: response.ok,
							}
						}

						throw sendLog({
							domain,
							message,
							custom,
						})
					}

					return response.json()
				})
				.catch(() => {
					throw sendLog({
						domain,
						message,
						custom,
					})
				})
		},
		[sendLog]
	)

	return { clientFetcher }
}
