'use client'

import { useProductListProvider } from 'product-list/context/ProductListProvider/useProductListProvider'
import { VIRTUAL_ITEM_CLASS } from 'product-list/hooks/useVirtualPages/useVirtualPages'
import { storeProductListState } from 'product-list/utils/productListState'
import { dataTestIds } from 'product-list/utils/testIds'
import { useEffect, useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { AddProductCardAnimation } from '../AddProductCardAnimation/AddProductCardAnimation'

import styles from './Cell.module.scss'

interface Props {
	showAsFeatured: boolean
	children?: React.ReactNode
	id: string
	gridIndex: number
	index: number
}

export function Cell({
	showAsFeatured,
	children,
	id,
	gridIndex,
	index,
}: Props) {
	const { name, view, count, lastSlotId } = useProductListProvider()
	const containerRef = useRef<HTMLLIElement>(null)

	const shouldBeFocused =
		!!lastSlotId && !!containerRef.current && id === lastSlotId

	useEffect(() => {
		const lastProductHandler = () => {
			storeProductListState(name, {
				slotId: id,
				count,
				view,
			})
		}
		const target = containerRef.current
		target?.addEventListener('click', lastProductHandler)
		return () => {
			target?.removeEventListener('click', lastProductHandler)
		}
	}, [count, id, name, view])

	useEffect(() => {
		if (shouldBeFocused) {
			containerRef.current.scrollIntoView({
				behavior: 'instant',
				block: 'center',
			})
		}
	}, [shouldBeFocused])

	return (
		<li
			ref={containerRef}
			data-slot={id}
			data-testid={dataTestIds.plpSlot}
			className={cx(
				applyStylesIf(showAsFeatured, styles.featured),
				VIRTUAL_ITEM_CLASS
			)}
		>
			<AddProductCardAnimation
				isFirstProductCard={gridIndex === 0 && index === 0}
			>
				{children}
			</AddProductCardAnimation>
		</li>
	)
}
