'use client'

import { type ReactNode, type RefObject } from 'react'
import { cx } from 'utils/cx'

import { HorizontalScrollControls } from './components/HorizontalScrollControls'
import { HorizontalScrollLink } from './components/HorizontalScrollLink'
import type { HorizontalScrollLinkProps } from './components/HorizontalScrollLink/HorizontalScrollLink'
import type { HorizontalScrollTextProps } from './components/HorizontalScrollText/HorizontalScrollText'
import { HorizontalScrollHeaderInfo } from './components/HorziontalScrollHeaderInfo/HorizontalScrollHeaderInfo'
import { useHorizontalScroll } from './hooks/useHorizontalScroll/useHorizontalScroll'

import styles from './HorizontalScroll.module.scss'

export interface HorizontalScrollProps {
	titleProps?: HorizontalScrollTextProps
	subtitleProps?: HorizontalScrollTextProps
	status?: string
	linkProps?: HorizontalScrollLinkProps
	expanded?: boolean
	children: ReactNode
	className?: string
	initialPosition?: number
	productCardClassName?: string
	'data-testid'?: string
	showPaddingCarousel?: boolean
	showPaddingHeader?: boolean
	showHeader?: boolean
}

/**
 * @deprecated use {@link Carousel} instead
 */
export const HorizontalScroll = ({
	titleProps,
	subtitleProps,
	status,
	linkProps,
	expanded,
	children,
	className,
	'data-testid': dataTestId,
	showPaddingCarousel = true,
	showPaddingHeader = true,
	showHeader = true,
	initialPosition,
}: HorizontalScrollProps) => {
	const {
		horizontalScrollRef,
		horizontalScrollEvents,
		handleLeftScroll,
		handleRightScroll,
		isAtStart,
		isAtEnd,
		shouldShowControls,
	} = useHorizontalScroll(initialPosition)

	return (
		<div
			className={cx(styles.horizontalScroll, className)}
			data-testid={dataTestId}
		>
			<div className={styles.horizontalScrollWrapper}>
				{showHeader ? (
					<div
						className={cx(
							styles.headerWrapper,
							showPaddingHeader && styles.headerPadding
						)}
					>
						<HorizontalScrollHeaderInfo
							titleProps={titleProps}
							subtitleProps={subtitleProps}
							status={status}
							showPaddingCarousel={showPaddingCarousel}
						/>
						<div className={styles.buttonsWrapper}>
							<HorizontalScrollLink {...linkProps} />
							{shouldShowControls && (
								<HorizontalScrollControls
									onPrevClick={handleLeftScroll}
									onNextClick={handleRightScroll}
									isPrevButtonDisabled={isAtStart}
									isNextButtonDisabled={isAtEnd}
									floatingArrows={!showHeader}
								/>
							)}
						</div>
					</div>
				) : (
					shouldShowControls && (
						<HorizontalScrollControls
							onPrevClick={handleLeftScroll}
							onNextClick={handleRightScroll}
							isPrevButtonDisabled={isAtStart}
							isNextButtonDisabled={isAtEnd}
							floatingArrows={!showHeader}
						/>
					)
				)}

				<div
					{...horizontalScrollEvents}
					ref={horizontalScrollRef as RefObject<HTMLDivElement>}
					className={cx(
						styles.carousel,
						expanded && styles.expanded,
						showPaddingCarousel && styles.paddingCarousel
					)}
					role='group'
					data-testid='carousel'
				>
					{children}
				</div>
			</div>
		</div>
	)
}
