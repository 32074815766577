'use client'

import { dataTestIds } from 'plp/constants/dataTestIds'
import type { BannerGroup } from 'plp/types/Banner'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { Options } from './Options/Options'

import styles from './Squared.module.scss'

export interface Props {
	data: BannerGroup
	catalogId: string
	collection?: string
}

export function Squared({ data, catalogId, collection }: Props) {
	const { isLargeOrGreater } = useResponsive()

	return (
		<div
			className={isLargeOrGreater ? styles.container : ''}
			data-testid={dataTestIds.plpQuickFilters('squared')}
		>
			<Options
				data={data.navigations}
				catalogId={catalogId}
				collection={collection}
			/>
		</div>
	)
}
