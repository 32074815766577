export enum EventName {
	APPLY_FILTERS = 'APPLY_FILTERS',
	APPLIED_FILTERS = 'APPLIED_FILTERS',
	ZERO_RESULTS = 'ZERO_RESULTS',
	DELETE_TAG = 'DELETE_TAG',
	DELETE_TAG_FILTERS = 'DELETE_TAG_FILTERS',
	CLEAR_FILTERS = 'CLEAR_FILTERS',
	OPEN_FILTERS = 'OPEN_FILTERS',
	CLOSE_FILTERS = 'CLOSE_FILTERS',
	CLIENT_GRID_PAGES = 'CLIENT_GRID_PAGES',
	UPDATE_TAGS = 'UPDATE_TAGS',
	PLP_END = 'PLP_END',
	CHANGE_COLUMNS = 'CHANGE_COLUMNS',
	SCROLL = 'SCROLL',
	TOP_NAVIGATION = 'TOP_NAVIGATION',
	FIRST_SCROLL = 'FIRST_SCROLL',
}

export type EventHandler<T> = (data: T) => void

export interface EventObserver {
	eventName: EventName
	handler: Function
	once?: boolean
}
