'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { CommBanner } from 'fukku/CommsBanners/CommBanner/CommBanner'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { cx } from 'utils/cx'

import { usePromoBannerData } from '../hooks/usePromoBannerData'
import { DEFAULT_MAX_DAYS } from '../utils/constants'
import {
	findBannerForBrand,
	getCommBannerLinks,
	isInverse,
	shouldRenderBanner,
	shouldShowPromoBanner,
} from '../utils/promoBannerHelpers'

import text from 'fukku/styles/texts.module.scss'

interface PromoBannerProps {
	hideBannerIfNotInRange?: boolean
	className?: string
	alternativeSubtitle?: string
	catalogId?: string
	brand?: string
	showDiscount?: boolean
}

export function PromoBanner({
	hideBannerIfNotInRange = false,
	className,
	alternativeSubtitle,
	brand,
	catalogId,
	showDiscount = false,
}: PromoBannerProps) {
	const promoData = usePromoBannerData()
	const { channel } = useMasterData()
	const promoBanner = findBannerForBrand({ channel, promoData, brand })

	const { enabled: promoBannerEnabled } = useFeatureFlag(
		featureFlagKeys.PromoBanner
	)

	if (!promoBannerEnabled || !promoBanner) {
		return null
	}

	const {
		promotion: {
			uid,
			startDate,
			endDate,
			subtitle,
			info,
			title: promoTitle,
			sectionCTAs,
			discount,
		},
		type,
		backgroundColorType,
		customBackgroundColor,
		contentColor,
		sections,
		sectionsWithoutCTAs,
		showCountdown,
		timeBeforeEnd,
	} = promoBanner

	if (hideBannerIfNotInRange && !showCountdown) {
		return null
	}

	const updatedMaxDays = timeBeforeEnd || DEFAULT_MAX_DAYS
	const countdownValue = showCountdown ? endDate : undefined

	const { isPromoActive, isInRange } = shouldShowPromoBanner(
		endDate,
		updatedMaxDays,
		startDate
	)
	const renderBanner = shouldRenderBanner(
		hideBannerIfNotInRange,
		isInRange,
		sections,
		catalogId
	)

	if (!isPromoActive || !renderBanner) {
		return null
	}

	const inverse = isInverse(contentColor, backgroundColorType)
	const promoSubtitle = alternativeSubtitle ?? subtitle

	const commBannerLinks = getCommBannerLinks(
		sectionsWithoutCTAs,
		sectionCTAs,
		catalogId,
		brand
	)

	return (
		<CommBanner
			id={uid}
			type={type}
			title={promoTitle}
			discount={showDiscount ? discount : undefined}
			links={commBannerLinks}
			subtitle={promoSubtitle}
			countdown={countdownValue}
			infoText={info}
			bgColorType={backgroundColorType}
			inverse={inverse}
			className={cx(text.bodyXL, className)}
			maxDays={updatedMaxDays}
			bgColorCode={customBackgroundColor}
		/>
	)
}
