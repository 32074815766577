import { AbTestStateContext } from 'ab-test/context/AbTestProvider'
import { useContext } from 'react'

export const useStateAbTest = () => {
	const value = useContext(AbTestStateContext)

	if (!value) {
		throw new Error('useStateAbTest must be used withing a AbTestProvider')
	}
	return value
}
