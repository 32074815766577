'use client'

import {
	CONVERSATIONAL_CHATBOT_OPEN_EVENT,
	LOAD_CHATBOT_SCRIPT_ID,
} from 'chatbot/constants/constants'
import { irisPro, irisTest } from 'chatbot/constants/urls'
import { useChatbotAnalytics } from 'chatbot/hooks/useChatbotAnalytics/useChatbotAnalytics'
import { useChatbotStorage } from 'chatbot/hooks/useChatbotStorage/useChatbotStorage'
import { EventListeners } from 'chatbot/types/EventListeners'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useMemo, useState } from 'react'
import { useSession } from 'session/src/hooks/useSession'
import { Channel, Environment } from 'types/masterData'
import { Events } from 'webview/constants/events'
import { sendAppEvent } from 'webview/utils/sendAppEvent'

export interface ChatbotProps {
	version: number
}
export const useChatbot = ({ version }: ChatbotProps) => {
	const { environment, isApp, country, channel } = useMasterData()
	const { email, externalId, name, isReady } = useSession()

	const { sendOpenChatbotAnalytic } = useChatbotAnalytics()
	const {
		getIsChatbotOpenStorage,
		saveIsChatbotOpenStorage,
		removeChatbotStorage,
		isConversationalIris,
	} = useChatbotStorage()

	const [isChatbotActive, setIsChatbotActive] = useState(false)
	const [isScriptLoaded, setIsScriptLoaded] = useState(false)

	useEffect(() => {
		const isChatbotOpenStorage = getIsChatbotOpenStorage()
		if (isChatbotOpenStorage && isReady) {
			openChatbot()
		}
	}, [isReady])

	useEffect(() => {
		if (isChatbotActive) {
			window.addEventListener(EventListeners.CHATBOT_CLOSED, closeChatbot, true)
		}

		return () => {
			window.removeEventListener(
				EventListeners.CHATBOT_CLOSED,
				closeChatbot,
				true
			)
		}
	}, [isChatbotActive])

	const chatbotScriptUrl = useMemo(() => {
		const isPro = environment === Environment.Pro

		const chatbotUrl = isPro ? irisPro : irisTest
		const timestamp = isPro ? version : new Date().getTime()

		return `${chatbotUrl}?t=${timestamp}`
	}, [])

	const chatbotConfig = useMemo(
		() => ({
			countryISO: country.countryISO,
			languageISO: country.languageISO,
			mail: email,
			firstName: name,
			externalID: externalId,
			isOutlet: channel === Channel.Outlet,
		}),
		[country, email, externalId, name, channel]
	)

	const openConversationalChatbot = () => {
		window.dispatchEvent(new Event(CONVERSATIONAL_CHATBOT_OPEN_EVENT))
	}

	const loadChatbotScript = () => {
		const script = document.createElement('script')

		script.src = chatbotScriptUrl
		script.setAttribute('id', LOAD_CHATBOT_SCRIPT_ID)
		script.setAttribute('data-chatbot-config', JSON.stringify(chatbotConfig))

		script.addEventListener(EventListeners.SCRIPT_LOADED, () => {
			setIsChatbotActive(true)
			setIsScriptLoaded(true)
		})
		script.addEventListener(EventListeners.SCRIPT_ERROR, () => {
			closeChatbot()
		})

		document.body.appendChild(script)
	}

	const openChatbot = () => {
		if (isApp) {
			sendAppEvent(Events.Chatbot)
		} else {
			sendOpenChatbotAnalytic()
			saveIsChatbotOpenStorage()

			/**
			 * Conversational chatbot does not remove script when it is closed, so we can open it directly
			 * if chatbot script is already loaded.
			 */
			if (isScriptLoaded && isConversationalIris()) {
				setIsChatbotActive(true)
				openConversationalChatbot()
				return
			}

			loadChatbotScript()
		}
	}

	const closeChatbot = () => {
		setIsChatbotActive(false)
		removeChatbotStorage()
	}

	return {
		chatbotScriptUrl,
		isChatbotActive,
		setIsChatbotActive,
		openChatbot,
	}
}
