import {
	COMM_BANNER_BG_COLOR_TYPE,
	COMM_BANNER_CONTENT_COLOR_TYPE,
} from 'fukku/CommsBanners/types'
import { Channel } from 'types/masterData'

import { DefaultBrand, type PromoBanner } from '../types/promoBanner'
import type { Section, SectionCTAs } from '../types/query'
import { DAY_IN_MS } from './constants'

export function shouldShowPromoBanner(
	promoEndDate: string,
	maxDaysToShowPromo: number,
	promoStartDate?: string | null
) {
	const promoEndDateMs = new Date(promoEndDate).getTime()
	const nowMs = new Date().getTime()
	// Date since the promo banner will be shown
	const showPromoDate = promoEndDateMs - maxDaysToShowPromo * DAY_IN_MS
	const promoStartDateMs = promoStartDate
		? new Date(promoStartDate).getTime()
		: 0

	return {
		isInRange: nowMs <= promoEndDateMs && nowMs >= showPromoDate,
		isPromoActive: promoStartDateMs <= nowMs && nowMs < promoEndDateMs,
	}
}

function matchesCatalogId(
	currentCatalogId: string,
	catalogIdToMatch: string
): boolean {
	const currentCatalogSubIds = currentCatalogId.split('.')
	const catalogSubIdsToMatch = catalogIdToMatch.split('.')
	if (currentCatalogSubIds.length === 1 || catalogSubIdsToMatch.length === 1) {
		return currentCatalogSubIds[0] === catalogSubIdsToMatch[0]
	}
	return currentCatalogId === catalogIdToMatch
}

export const shouldRenderBanner = (
	hideBannerIfNotInRange: boolean,
	isInRange: boolean,
	sections: string[],
	catalogId?: string
) => {
	const showBanner = isInRange || !hideBannerIfNotInRange

	if (!showBanner) {
		return false
	}

	if (catalogId) {
		return sections.some((section) => matchesCatalogId(catalogId, section))
	}
	return true
}

export const isInverse = (
	contentColor: COMM_BANNER_CONTENT_COLOR_TYPE,
	backgroundBanner: COMM_BANNER_BG_COLOR_TYPE
) => {
	return (
		contentColor === COMM_BANNER_CONTENT_COLOR_TYPE.WHITE &&
		backgroundBanner !== COMM_BANNER_BG_COLOR_TYPE.WHITE
	)
}

export const getCommBannerLinks = (
	sectionsWithoutCTAs: Section[],
	sectionCTAs: SectionCTAs[],
	catalogId: string | undefined,
	brand: string | undefined
) => {
	if (!brand) {
		return []
	}

	const section = sectionsWithoutCTAs.find(
		(withoutCTASection) => withoutCTASection.brand === brand
	)?.section

	const [catalogIdSection] = catalogId?.split('.') ?? []

	const foundSectionWithoutCTA = sectionsWithoutCTAs.find(
		(withoutCTASection) => {
			return catalogIdSection && withoutCTASection.section === catalogIdSection
		}
	)

	if (foundSectionWithoutCTA) {
		return []
	}

	let ctas: SectionCTAs[] = []
	if (catalogIdSection && section !== catalogIdSection) {
		ctas = sectionCTAs.filter(
			(cta) => cta.sectionID === section && !!cta.url && !!cta.labelKey
		)
	}

	return ctas.map((cta) => ({
		children: cta.labelKey,
		href: cta.url,
		'data-testid': cta.sectionID,
	}))
}

export const findBannerForBrand = ({
	channel,
	promoData,
	brand,
}: {
	channel: Channel
	promoData: PromoBanner[]
	brand?: string
}) => {
	if (channel !== Channel.Shop && channel !== Channel.Outlet) {
		return undefined
	}
	return promoData.find(
		(promo) =>
			promo.channel === channel &&
			(promo.brand === brand || promo.brand === DefaultBrand[channel])
	)
}
