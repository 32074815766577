import { formatCustomDate } from 'utils/formatCustomDate'

export interface OptionalParams {
	customDate: Date | null
	test: boolean
}

export function getHeadersAndParamsFromOptionals(
	currentParams: Record<string, string>,
	optional?: OptionalParams
) {
	const params: Record<string, string> = currentParams

	/** When timestamp param different to now is defined */
	const optionalCustomDateHeader = optional?.customDate
		? {
				'x-custom-date': formatCustomDate(optional.customDate),
			}
		: undefined
	if (optional?.test) {
		params.test = 'true'
	}
	return {
		params,
		headers: {
			...optionalCustomDateHeader,
		},
	}
}
