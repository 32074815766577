import { mappers } from 'product-list/context/ProductListFiltersProvider/mappers'
import { deserialize } from 'product-list/context/ProductListFiltersProvider/serializer/serializer'
import {
	catalogItems,
	getFilterId,
	getRangePriceLimits,
} from 'product-list/context/ProductListFiltersProvider/utils'
import type {
	FiltersData,
	FiltersItemData,
	FiltersSearchParams,
	RangePickerValue,
	UserFilter,
} from 'product-list/types/Filters'
import type { ProductListGroup } from 'product-list/types/ProductsList'

function getSelection(
	filters: UserFilter[],
	fromQueryParams: { id: string; selection: string[] }[]
) {
	return filters.map((filter) => {
		const found = fromQueryParams.find(
			(option) => option.id === getFilterId(filter)
		)
		if (!found) {
			return filter
		}
		return {
			...filter,
			selection: found.selection,
		} as UserFilter
	})
}

export interface UseFiltersData {
	filters: UserFilter[]
	catalogItemsData: FiltersItemData[] | undefined
	range: RangePickerValue | undefined
}

interface GetFiltersDataProps {
	groups: ProductListGroup[]
	defaultFilter?: FiltersSearchParams
	serverData: FiltersData
}

export function getFiltersData({
	groups,
	defaultFilter,
	serverData,
}: GetFiltersDataProps): UseFiltersData {
	const fromQueryParams = deserialize(defaultFilter)
	const filtersFromData = mappers(serverData)
	const currentCatalogItemsData = catalogItems(serverData, groups)
	const filters = fromQueryParams?.options
		? getSelection(filtersFromData, fromQueryParams.options)
		: filtersFromData

	return {
		filters,
		catalogItemsData: currentCatalogItemsData,
		range: getRangePriceLimits(currentCatalogItemsData),
	}
}
