'use client'

import { View } from 'product-list/types/View'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useMemo,
	useState,
} from 'react'

export interface PlpProductListContextType {
	defaultView: View
	currentView: View
	setCurrentView: Dispatch<SetStateAction<View>>
}

interface PlpProductListProviderProps {
	defaultView: View
	children: React.ReactNode
}

const defaultContext: PlpProductListContextType = {
	defaultView: View.Standard,
	currentView: View.Standard,
	setCurrentView: () => {},
}

export const PlpProductListContext =
	createContext<PlpProductListContextType>(defaultContext)

export const PlpProductListProvider = ({
	defaultView,
	children,
}: PlpProductListProviderProps) => {
	const [currentView, setCurrentView] = useState(defaultView)

	const contextState: PlpProductListContextType =
		useMemo<PlpProductListContextType>(
			() => ({
				defaultView,
				currentView,
				setCurrentView,
			}),
			[defaultView, currentView]
		)

	return (
		<PlpProductListContext.Provider value={contextState}>
			{children}
		</PlpProductListContext.Provider>
	)
}
