import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { PlpActionEvents, PlpGenericEvents } from 'plp/types/Analytics'
import type { SelectedFilters } from 'product-list/types/Filters'
import { View } from 'product-list/types/View'

import { filtersToAnalytics } from './filtersToAnalytics'

function sendApplyFiltersEvent(
	appliedFilters: SelectedFilters,
	layoutView: View | null
) {
	const payload = filtersToAnalytics(appliedFilters)
	sendGenericEvent(PlpGenericEvents.APPLY_FILTERS, {
		...payload,
		layout_view: layoutView,
	})
}

function sendOpenFiltersEvent(filtered: boolean, layoutView: View | null) {
	const payload = {
		filtered,
		layout_view: layoutView,
	}
	sendGenericEvent(PlpGenericEvents.OPEN_FILTERS, payload)
}

function sendCloseFiltersEvent(filtered: boolean, layoutView: View | null) {
	const payload = {
		filtered,
		layout_view: layoutView,
	}
	sendGenericEvent(PlpGenericEvents.CLOSE_FILTERS, payload)
}

function sendPlpEndEvent(filtered: boolean, layoutView: View | null) {
	const payload = {
		filtered,
		layout_view: layoutView,
	}
	sendGenericEvent(PlpGenericEvents.PLP_END, payload)
}

function sendLayoutViewChange(filtered: boolean, layoutView: View | null) {
	const payload = {
		label_1: PlpActionEvents.CHANGE_LAYOUT_VIEW,
		layout_view: layoutView,
		filtered,
	}
	sendGenericEvent(PlpGenericEvents.PLP_ACTION, payload)
}

function sendNoResultFiltersEvent(filtered: boolean, layoutView: View | null) {
	const payload = {
		filtered,
		layout_view: layoutView,
	}
	sendGenericEvent(PlpGenericEvents.NO_RESULTS_FILTERS, payload)
}

export const analyticsEvents = {
	sendNoResultFiltersEvent,
	sendApplyFiltersEvent,
	sendOpenFiltersEvent,
	sendCloseFiltersEvent,
	sendPlpEndEvent,
	sendLayoutViewChange,
}
