import type { GetterProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'

import { getProductCardSettings } from '../getProductCardSettings'

export const getProductCardM: GetterProductCardSettings = (ctx) => ({
	...getProductCardSettings(ctx),
	hasColorPicker: false,
	shouldRenderColorSheetButton: true,
	shouldRenderAddButtonAsPrimary: true,
})
