import { useContext } from 'react'

import { PromoBannerContext } from '../context/PromoBannerProvider'
import type { PromoBanner } from '../types/promoBanner'

export const usePromoBannerData = (): PromoBanner[] => {
	const promoBannerData: PromoBanner[] | null = useContext(PromoBannerContext)

	if (!promoBannerData) {
		throw new Error(
			'usePromoBannerData must be used within a PromoBannerProvider'
		)
	}
	return promoBannerData
}
