export const enum PRODUCT_LIST_QUERY_PARAMS {
	order = 'order',
	filters = 'filters',
	range = 'range',
	view = 'view',
}

export const ALL_PRODUCT_LIST_QUERY_PARAMS = [
	PRODUCT_LIST_QUERY_PARAMS.order,
	PRODUCT_LIST_QUERY_PARAMS.filters,
	PRODUCT_LIST_QUERY_PARAMS.range,
	PRODUCT_LIST_QUERY_PARAMS.view,
]
