'use client'

import { START_LIST_ID } from 'product-list/constants/startList'

import styles from './ProductListHook.module.scss'

export function scrollToProductListHook({
	isSmooth = true,
}: {
	isSmooth?: boolean
}) {
	const startList = globalThis.document.getElementById(START_LIST_ID)
	if (startList?.scrollIntoView) {
		startList.scrollIntoView({ behavior: isSmooth ? 'smooth' : 'instant' })
	}
}

export function ProductListHook() {
	return (
		<div
			className={styles.start}
			id={START_LIST_ID}
			data-testid={START_LIST_ID}
		/>
	)
}
