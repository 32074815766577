import type { GetterProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { ProductCardVariant } from 'product-card/types'

import { getProductCardSettings } from '../getProductCardSettings'

export const getProductCardO: GetterProductCardSettings = (ctx) => ({
	...getProductCardSettings(ctx),
	hasColorPicker: false,
	shouldRenderFeatured: true,
	shouldRenderSizes: false,
	shouldRenderSlider: false,
	variant: ProductCardVariant.Overview,
})
