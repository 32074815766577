import type { FiltersData } from 'product-list/types/Filters'
import { fetcher } from 'product-list/utils/fetcher'

export async function getFromClientFiltersData({
	endpoint,
	language,
	customDate,
	test,
}: {
	endpoint: string
	language: string
	customDate: Date | null
	test: boolean
}): Promise<FiltersData> {
	return fetcher(endpoint, language, { customDate, test })
}
