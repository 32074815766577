import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useSearchParams } from 'next/navigation'
import { PRODUCT_LIST_QUERY_PARAMS } from 'product-list/constants/queryParams'
import { START_LIST_ID } from 'product-list/constants/startList'
import { DEFAULT_ORDER } from 'product-list/context/ProductListFiltersProvider/constants'
import { useProductListProvider } from 'product-list/context/ProductListProvider/useProductListProvider'
import { useCallback } from 'react'
import { env } from 'utils/envManager'

interface SerializedFilterParams {
	range?: string
	order: string
	filters: string
}

export function useFiltersSearchParams(filterResultPathname: string) {
	const searchParams = useSearchParams()
	const navigation = useNavigation()

	const { view, defaultView } = useProductListProvider()

	const getSearchParams = useCallback(
		(serializedParams: SerializedFilterParams) => {
			const current = new URLSearchParams(Array.from(searchParams.entries()))
			current.delete(PRODUCT_LIST_QUERY_PARAMS.filters)
			current.delete(PRODUCT_LIST_QUERY_PARAMS.order)
			current.delete(PRODUCT_LIST_QUERY_PARAMS.range)
			if (serializedParams.range) {
				current.set(PRODUCT_LIST_QUERY_PARAMS.range, serializedParams.range)
			}
			if (serializedParams.order !== DEFAULT_ORDER) {
				current.set(PRODUCT_LIST_QUERY_PARAMS.order, serializedParams.order)
			}
			if (serializedParams.filters?.length) {
				current.set(PRODUCT_LIST_QUERY_PARAMS.filters, serializedParams.filters)
			}
			if (view && defaultView !== view) {
				current.set(PRODUCT_LIST_QUERY_PARAMS.view, view)
			} else {
				current.delete(PRODUCT_LIST_QUERY_PARAMS.view)
			}
			return current
		},
		[defaultView, searchParams, view]
	)

	const getUrlToNavigate = useCallback(
		(serializedParams: SerializedFilterParams) => {
			const current = getSearchParams(serializedParams)
			const newUrl = new URL(
				filterResultPathname,
				env.NEXT_PUBLIC_CURRENT_ORIGIN
			)
			newUrl.search = current.toString()
			return newUrl
		},
		[filterResultPathname, getSearchParams]
	)

	const push = useCallback(
		(serializedParams: SerializedFilterParams) => {
			const newUrl = getUrlToNavigate(serializedParams)
			navigation.navigate(`${newUrl.href}#${START_LIST_ID}`)
		},
		[getUrlToNavigate, navigation]
	)

	const prefetch = useCallback(
		(serializedParams: SerializedFilterParams) => {
			const newUrl = getUrlToNavigate(serializedParams)
			navigation.prefetch(newUrl.href)
		},
		[getUrlToNavigate, navigation]
	)

	return {
		push,
		prefetch,
	}
}
