import { FilterType, type UserFilter } from 'product-list/types/Filters'

import { ALL } from './constants'
import { deepClone } from './utils'

function uniqueSelection(filter: UserFilter, value: string) {
	let selection = [ALL]
	if (value !== ALL && !filter.selection.includes(value)) {
		selection = [value]
	}

	return { ...filter, selection }
}

function updatedSelection(
	isUniqueOption: boolean,
	filter: UserFilter,
	value: string,
	toggle = true
) {
	const currentFilter = deepClone(filter)
	if (isUniqueOption) {
		return uniqueSelection(currentFilter, value)
	}

	if (currentFilter.selection.includes(value)) {
		currentFilter.selection = currentFilter.selection.filter(
			(item) => item !== value
		)
		return currentFilter
	}

	if (toggle) {
		currentFilter.selection.push(value)
	}

	return currentFilter
}

export function updateFilter(
	type: string,
	id: string | undefined,
	value: string,
	oldState: UserFilter[],
	toggle = true
): UserFilter[] {
	return oldState.map((filter) => {
		const newFilter = deepClone(filter)

		if (newFilter.type !== type) {
			return newFilter
		}

		if (newFilter.type === FilterType.generic && newFilter.id !== id) {
			return newFilter
		}

		const isUniqueOption = !newFilter.multipleChoice
		return updatedSelection(isUniqueOption, newFilter, value, toggle)
	})
}
