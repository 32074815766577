import type { BannerExtra } from 'plp/types/Banner'

import styles from './Title.module.scss'

interface Props {
	data: BannerExtra
	language: string
}

export default function Title({ data, language }: Props) {
	const [primaryTitle, ...secondaryTitles] = data.title.split(' ')
	const currentMonth = data.month
		? new Date().toLocaleString(language, { month: 'long' })
		: undefined

	return (
		<div className={styles.box}>
			{!!currentMonth && <div className={styles.month}>{currentMonth}</div>}
			<div className={styles.title} aria-label={data.title}>
				<div className={styles.title1} aria-hidden={true}>
					{primaryTitle}
				</div>
				<div className={styles.title2} aria-hidden={true}>
					{secondaryTitles.join(' ')}
				</div>
			</div>
			<div className={styles.description}>{data.description}</div>
		</div>
	)
}
