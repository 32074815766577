import { AbTestDispatchContext } from 'ab-test/context/AbTestProvider'
import { useContext } from 'react'

export const useSetAbTestRequest = () => {
	const setAbTestRequest = useContext(AbTestDispatchContext)

	if (!setAbTestRequest) {
		throw new Error('useSetAbTestRequest must be used withing a AbTestProvider')
	}
	return setAbTestRequest
}
