import { HorizontalScroll } from 'fukku/HorizontalScroll'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { dataTestIds } from 'plp/constants/dataTestIds'
import { TopNavigationType } from 'plp/types/Analytics'
import type { BannerItem } from 'plp/types/Banner'
import { EventName } from 'plp/types/eventsObserver'
import { eventsObserver } from 'plp/utils/eventsObserver'
import { getSubsectionUrl } from 'plp/utils/getSubsectionUrl/getSubsectionUrl'
import { useMemo } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import Collage from './Collage/Collage'

import styles from './Options.module.scss'

const NO_ITEM_ACTIVE = -1
const ARRAY_START_IDX = 0
const MAX_COLLAGE_ITEMS = 4

interface Props {
	data: BannerItem[]
	catalogId: string
	collection?: string
}

export default function Options({ data, catalogId, collection }: Props) {
	const { isLessThanLarge } = useResponsive()
	const searchParams = useSearchParams()
	const items = useMemo(() => {
		const activeItemIdx = data.findIndex((item) => {
			const currentCollection = item.collection ?? `:${collection ?? ''}`
			return item.catalogId === `${catalogId}${currentCollection}`
		})
		return data.map((item, idx) => {
			const url = getSubsectionUrl(item.url, searchParams, item.collection)
			const currentCollection = item.collection ?? `:${collection ?? ''}`
			return (
				<div
					key={`${item.catalogId}${currentCollection}`}
					className={styles.item}
				>
					{item.image ? (
						<ClientLink
							className={cx(
								styles.link,
								activeItemIdx === idx && styles.active
							)}
							href={`${url.pathname}${url.search}`}
							isLocalizedPath
							onClick={() =>
								eventsObserver.dispatch(EventName.TOP_NAVIGATION, {
									subfamilies: item.collection,
									type: TopNavigationType.IMAGE,
								})
							}
							data-testid={dataTestIds.plpQuickFiltersOption('rounded', idx)}
						>
							<div key={item.linkId} className={styles.regularItem}>
								<div className={styles.imageContainer}>
									<Image
										src={item.image}
										alt=''
										sizes='(min-width: 840px) 136px, 120px'
										fill
									/>
								</div>
								<p data-testid={dataTestIds.plpQuickFiltersTitle('rounded')}>
									{item.title}
								</p>
							</div>
						</ClientLink>
					) : (
						<ClientLink
							className={cx(
								styles.link,
								activeItemIdx === NO_ITEM_ACTIVE && styles.active
							)}
							href={`${url.pathname}${url.search}`}
							isLocalizedPath
							data-testid={dataTestIds.plpQuickFiltersOption('rounded', idx)}
						>
							<Collage
								title={item.title}
								items={data
									.filter((elem) => !!elem.image)
									.slice(ARRAY_START_IDX, MAX_COLLAGE_ITEMS)}
							/>
						</ClientLink>
					)}
				</div>
			)
		})
	}, [catalogId, collection, data, searchParams])

	return (
		<div
			className={styles.container}
			data-testid={dataTestIds.plpQuickFiltersOptions('rounded')}
		>
			{isLessThanLarge ? (
				<HorizontalScroll
					titleProps={{ content: '', className: '' }}
					data-testid={dataTestIds.plpQuickFiltersHorizontalScroll('rounded')}
				>
					{items}
				</HorizontalScroll>
			) : (
				<>{items}</>
			)}
		</div>
	)
}
