'use client'

import { InspirationalClientLink } from 'fukku/Button/ClientLink/Inspirational'
import {
	BUTTON_INSPIRATIONAL_STATE,
	LINK_SIZE,
} from 'fukku/Button/Inspirational/constants'
import { useSearchLink } from 'links/search/hook/useSearchLink'
import type { BrandId } from 'types/brands'

import { TEST_IDS } from '../../../../constants/resultsList'

import styles from './BrandLinkItem.module.scss'

type BrandLinkItemProps = {
	brandId: BrandId
	searchTerm: string
	children: React.ReactNode
	accessibilityLabel: string
	onChangeState: (barndId: BrandId | null) => void
	active: boolean
}
export function BrandLinkItem({
	brandId,
	searchTerm,
	accessibilityLabel,
	children,
	onChangeState,
	active,
}: BrandLinkItemProps) {
	const href = useSearchLink({ brandId, searchTerm })

	const setActive = () => onChangeState(brandId)
	const setUnselected = () => onChangeState(null)

	if (!href) {
		return null
	}

	return (
		<li key={brandId}>
			<InspirationalClientLink
				className={styles.brandLinkItem}
				size={LINK_SIZE.S}
				state={
					active
						? BUTTON_INSPIRATIONAL_STATE.ACTIVE
						: BUTTON_INSPIRATIONAL_STATE.UNSELECTED
				}
				href={href}
				data-testid={`${TEST_IDS.BRAND_LINK}${brandId}`}
				linkProps={{
					'aria-label': accessibilityLabel,
					onFocus: setActive,
					onBlur: setUnselected,
					onMouseOver: setActive,
					onMouseLeave: setUnselected,
				}}
			>
				{children}
			</InspirationalClientLink>
		</li>
	)
}
