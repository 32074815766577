import { useContext } from 'react'

import { PlpProductListContext } from './PlpProductListProvider'

/** @throws {Error} If usePlpContext is used without PlpProvider */
export const usePlpProductListProvider = () => {
	const context = useContext(PlpProductListContext)
	if (!context) {
		throw new Error(
			'usePlpProductListProvider must be used within a PlpProductListProvider'
		)
	}
	return context
}
