'use client'

import type { ReactNode } from 'react'
import { cx } from 'utils/cx'

import styles from './CookiesWrapper.module.scss'

interface Props {
	children: ReactNode
}

export const CookiesWrapper = ({ children }: Props) => {
	return (
		<aside
			aria-labelledby='cbanner-title'
			className={cx(styles.cookiesWrapper, styles.middle)}
			data-testid='cookies.layout'
		>
			{children}
		</aside>
	)
}
