'use client'

import { createContext } from 'react'

import type { PromoBanner } from '../types/promoBanner'

export const PromoBannerContext = createContext<PromoBanner[] | null>(null)

export const PromoBannerProvider = ({
	promoBannerData,
	children,
}: {
	promoBannerData: PromoBanner[]
	children: React.ReactNode
}) => {
	return (
		<PromoBannerContext.Provider value={promoBannerData}>
			{children}
		</PromoBannerContext.Provider>
	)
}
