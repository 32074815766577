'use client'

import { createContext, useMemo } from 'react'

import { useHandleSegmentation } from '../../hooks/useHandleSegmentation'

export interface SegmentationContextType {
	segments: { [id: string]: string }
}

interface SegmentationProviderProps {
	children: React.ReactNode
}

const defaultContext: SegmentationContextType = {
	segments: {},
}

export const SegmentationContext =
	createContext<SegmentationContextType>(defaultContext)

export const SegmentationProvider = ({
	children,
}: SegmentationProviderProps) => {
	const segments = useHandleSegmentation()
	const contextState: SegmentationContextType =
		useMemo<SegmentationContextType>(
			() => ({
				segments,
			}),
			[segments]
		)

	return (
		<SegmentationContext.Provider value={contextState}>
			{children}
		</SegmentationContext.Provider>
	)
}
