import {
	type ResponsiveBehaviors,
	getResponsiveBehaviors,
	isResponsiveBehavior,
	isResponsiveBehaviorSimple,
} from '../../behaviors/behaviors'
import { useResponsive } from '../useResponsive/useResponsive'

const INVALID_BEHAVIORS = 'Invalid behaviors'
/**
 * @throws {Error} Invalid behavior
 */
export function useResponsiveBehaviors<K extends string, T>(
	behavior: ResponsiveBehaviors<K, T>,
	type: K
): T {
	const {
		isSmall,
		isMedium,
		isLarge,
		isExtraLargeOrGreater,
		isLessThanLarge,
		isLargeOrGreater,
	} = useResponsive()

	try {
		if (isResponsiveBehavior(behavior[type])) {
			if (isSmall) {
				return getResponsiveBehaviors(behavior, type, 'small')
			}
			if (isMedium) {
				return getResponsiveBehaviors(behavior, type, 'medium')
			}
			if (isLarge) {
				return getResponsiveBehaviors(behavior, type, 'large')
			}
			if (isExtraLargeOrGreater) {
				return getResponsiveBehaviors(behavior, type, 'xlarge')
			}
		} else if (isResponsiveBehaviorSimple(behavior[type])) {
			if (isLessThanLarge) {
				return getResponsiveBehaviors(behavior, type, 'lessThanLarge')
			}
			if (isLargeOrGreater) {
				return getResponsiveBehaviors(behavior, type, 'largeOrGreater')
			}
		} else {
			throw new Error(INVALID_BEHAVIORS)
		}
	} catch (e) {
		throw new Error(INVALID_BEHAVIORS)
	}
	return getResponsiveBehaviors(behavior, type, 'default')
}
