import { useContext } from 'react'

import { SetStateLabelsContext } from '../../context/LabelsProvider/LabelsProvider'

/** @throws {Error} If useLabels is used without LabelsProvider */
export const useSetLabels = () => {
	const setLabels = useContext(SetStateLabelsContext)
	if (!setLabels) {
		throw new Error('useSetLabels must be used withing a LabelsProvider')
	}
	return setLabels
}
