import type {
	EventHandler,
	EventName,
	EventObserver,
} from 'plp/types/eventsObserver'

export class EventsObserver {
	observers: EventObserver[]

	constructor() {
		this.observers = []
	}

	static emit<T>(list: EventObserver[], data: T) {
		list.forEach((observer) => {
			if (observer && typeof observer.handler === 'function') {
				observer.handler(data)
			}
		})
	}

	subscribe<T>(eventName: EventName, handler: EventHandler<T>, once = false) {
		if (typeof handler !== 'function') {
			throw new Error('Handler must be a function')
		}
		this.observers.push({ eventName, handler, once })
	}

	unsubscribe<T>(handler: EventHandler<T>) {
		this.observers = this.observers.filter(
			(observer) => observer.handler !== handler
		)
	}

	dispatch<T>(eventName: EventName, data?: T) {
		EventsObserver.emit<T>(
			this.observers.filter((observer) => observer.eventName === eventName),
			data || ({} as T)
		)
		this.observers = this.observers.filter(
			(observer) => !(observer.eventName === eventName && observer.once)
		)
	}
}
export const instance = new EventsObserver()
