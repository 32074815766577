import { cx } from 'utils/cx'

import { ButtonBase } from '../ButtonBase'

import styles from './ButtonGhost.module.scss'

export function ButtonGhost(
	props: React.ComponentProps<'button'>
): React.ReactNode {
	const { className, ...rest } = props

	return <ButtonBase {...rest} className={cx(className, styles.default)} />
}
