import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import type { AbTestKeys } from 'types/constants/AbTestKeys'
import type { UserContext } from 'types/featureFlag'

interface HandleDecisionProps {
	userContext: UserContext
	key: AbTestKeys
	abTestActivated: boolean
	attributes?: Record<string, unknown>
}

export const handleDecision = ({
	userContext,
	key,
	abTestActivated,
	attributes,
}: HandleDecisionProps) => {
	if (!userContext) {
		return null
	}

	if (attributes) {
		Object.keys(attributes).forEach((attributeKey) => {
			userContext.setAttribute(attributeKey, attributes[attributeKey])
		})
	}
	const { enabled, variables, ruleKey, variationKey } = userContext.decide(
		key,
		// @ts-expect-error - Can't import Optimizely enum or we get unused javascript (no tree shaking)
		abTestActivated ? ['DISABLE_DECISION_EVENT'] : []
	)

	if (!abTestActivated) {
		sendGenericEvent(`experiment-${ruleKey}`, {
			experimentId: ruleKey,
			variantId: variationKey,
			featureFlagId: key,
		})
	}
	return { enabled, variables, ruleKey, variationKey }
}
