import { addFavorites } from 'favorite/services/addFavorites'
import type { FavoriteProduct, FavoritesState } from 'favorite/types/types'
import { favoritesCache } from 'favorite/utils/favoritesCache'
import { getCacheFavorites } from 'favorite/utils/getCacheFavorites'
import type { Dispatch } from 'react'

import { FAVORITES_LIST_KEY } from '../../constants/constants'
import { addNewFavorite } from './addNewFavorite'

export interface AddProductType {
	product: FavoriteProduct
	logged: boolean
	token: string | null
	countryISO: string
	languageISO: string
	favoritesDispatch: Dispatch<Partial<FavoritesState>>
	isSavedForLater: boolean
}

const failedToAddFavorite = () => {
	throw new Error('Failed to add favorite')
}

export const addProduct = async ({
	product,
	logged,
	token,
	countryISO,
	languageISO,
	favoritesDispatch,
	isSavedForLater = false,
}: AddProductType): Promise<void> => {
	const favorites = await getCacheFavorites()

	if (token && logged) {
		const response = await addFavorites({
			products: [
				{
					ref: product.ref,
					color: product.color,
					isSavedForLater,
				},
			],
			token,
			countryISO,
			languageISO,
		})

		if (!response.ok) {
			failedToAddFavorite()
		}

		const json = await response.json()

		if (json?.[0]?.id) {
			const { id } = json[0]
			const objectToAdd = addNewFavorite(favorites, { ...product, id })
			await favoritesCache()?.addKeyToCache(FAVORITES_LIST_KEY, objectToAdd)
			favoritesDispatch({ favorites: objectToAdd })
		}
	} else {
		const objectToAdd = addNewFavorite(favorites, product)
		await favoritesCache()?.addKeyToCache(FAVORITES_LIST_KEY, objectToAdd)
		favoritesDispatch({ favorites: objectToAdd })
	}

	return Promise.resolve()
}
