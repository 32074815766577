import { ClientProductCard } from 'product-card/components/client/ClientProductCard'
import { ProductDataProvider } from 'product-card/context/ProductDataContext'
import type { ProductListItem } from 'product-list/types/ProductsList'
import type { Product } from 'product/types'
import { ProductLocations } from 'product/types/ProductLocations'

export function ClientProductCardItem({
	item,
	preLoadedProductsData,
}: {
	item: ProductListItem
	preLoadedProductsData: Product[]
}) {
	const product = preLoadedProductsData.find(
		(productItem) => item.productId === productItem.id
	)

	const isTwoColumnSize = !!item.images.landscape
	const card = (
		<ClientProductCard
			imageSortCriteria={item.images.order}
			productId={item.productId}
			colorId={item.colorId}
			lookId={item.lookId || '00'}
			isTwoColumnSize={isTwoColumnSize}
			dataTestId={'dataTestIds.plpProductCard'}
			location={ProductLocations.Grid}
		/>
	)
	return !!product ? (
		<ProductDataProvider initialProductData={product}>
			{card}
		</ProductDataProvider>
	) : (
		card
	)
}
