'use client'

import { useMenus } from 'header/hooks/useMenus'
import { setMinWidthForStructure } from 'header/utils/menu/minWidthForStructureLPlus'
import { useNavigationBrand } from 'hooks/useNavigationBrand/useNavigationBrand'
import { useEffect, useRef } from 'react'
import type { Brand, BrandId } from 'types/brands'

import { MenuRefType } from '../../../../context/MenuContext/MenuRefContext'
import { BrandsUnderline } from '../BrandsUnderline/BrandsUnderline'
import { BrandEntry } from './BrandEntry/BrandEntry'

import styles from './Brands.module.scss'

export interface BrandMenu {
	id: BrandId
	labelKey: string
	subBrands?: Brand[]
	url: string
}

type BrandsProps = {
	brands: BrandMenu[]
	isTopBar?: boolean
	handleOpenMenu?: () => void
}

export function Brands({
	brands,
	isTopBar = false,
	handleOpenMenu,
}: BrandsProps) {
	const { brand: defaultBrand } = useNavigationBrand()
	const { open, updateRefs } = useMenus()
	const refBrands = useRef<HTMLUListElement>(null)

	useEffect(() => {
		if (refBrands.current) {
			setMinWidthForStructure(refBrands.current, open)
		}
	}, [refBrands.current, open])

	useEffect(() => {
		if (refBrands.current) {
			updateRefs(MenuRefType.BRANDS, refBrands)
		}
	}, [refBrands.current])

	if (brands.length === 0) {
		return null
	}

	return (
		<nav>
			<ul className={styles.brandsList} ref={refBrands}>
				{brands.map((brand, index) => (
					<BrandEntry
						key={brand.id}
						brand={brand}
						brandIndex={index}
						isTopBar={isTopBar}
						handleOpenMenu={handleOpenMenu}
						defaultBrand={!isTopBar ? defaultBrand : undefined}
					/>
				))}
			</ul>
			{isTopBar && <BrandsUnderline />}
		</nav>
	)
}
