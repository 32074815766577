'use client'

import { useRef } from 'react'
import { useResponsiveBehavior } from 'responsive/hooks/useResponsiveBehavior/useResponsiveBehavior'
import { env } from 'utils/envManager'

import { BannerWrapper } from '../../../BannerWrapper/BannerWrapper'

const mlyAnimationUrl = new URL(
	'/videos/mangoLikesYou/mly-home-acquisition.mp4',
	env.NEXT_PUBLIC_SITE_BASE_URL
).href

const SIZE_SMALL = 263
const SIZE_MEDIUM = 384
const SIZE_LARGE = 401

const animationResponsiveBehavior = {
	default: {
		width: SIZE_SMALL,
		height: SIZE_SMALL,
	},
	medium: {
		width: SIZE_MEDIUM,
		height: SIZE_MEDIUM,
	},
	large: {
		width: SIZE_LARGE,
		height: SIZE_LARGE,
	},
	xlarge: {
		width: SIZE_LARGE,
		height: SIZE_LARGE,
	},
}

export interface MangoLikesYouImageProps {
	className?: string
	altAnimationDesc: string
	isPrioritySlot: boolean
}

export function MangoLikesYouAnimation({
	className,
	altAnimationDesc,
	isPrioritySlot,
}: MangoLikesYouImageProps): React.ReactNode {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const { width, height } = useResponsiveBehavior<{
		width: number
		height: number
	}>(animationResponsiveBehavior)

	return (
		<BannerWrapper
			ref={wrapperRef}
			priority={isPrioritySlot}
			className={className}
		>
			<video
				width={width}
				height={height}
				aria-label={altAnimationDesc}
				autoPlay={true}
				playsInline={true}
				loop={true}
				muted={true}
			>
				<source src={mlyAnimationUrl} type='video/mp4' />
				<track kind='captions' default />
			</video>
		</BannerWrapper>
	)
}
