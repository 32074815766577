export enum ProductLocations {
	Main = 'main', // -> detail
	Sticky = 'sticky', // -> detail -> sticky
	ColorSelector = 'color-selector', // -> detail -> color selector
	ColorSelectorSheet = 'color-selector-sheet', // -> detail -> color selector bottom sheet
	Grid = 'list', // -> plp, favorites?, search?
	Search = 'search_results',
	Carousel = 'product-carousel', // -> ??
	FashionAssistant = 'fashion-assistant', // -> fashion assistant
	CrossSellingSimilars = 'pdp-cross-selling:similars',
	CrossSellingTotalLook = 'pdp-cross-selling:total-look:main',
	CrossSellingPersonalization = 'pdp-cross-selling:recommended',
	CrossSellingShoppingCartPersonalization = 'bag:cross-selling:recommended',
	CrossSellingLastViewed = 'pdp-cross-selling:last-viewed',
	CrossSellingCollaborativeRecommendationsFavorite = 'favorite-cross-selling:collaborative',
	CrossSellingEmptyShoppingCartPersonalization = 'bag-empty:cross-selling:recommended',
	CrossSellingBestSeller = 'home-cross-selling:best-sellers',
	CrossSellingOurSelection = 'home-cross-selling:our-selection',
	CrossSellingFavoritesTotalLook = 'favorite-cross-selling:total-look',
	CrossSellingCollections = 'favorite-cross-selling:collections',
	ShopTheLook = 'pdp-cross-selling:total-look:shop-the-look',
	SizeSelector = 'size-selector',
}
