import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { useProductListAnalyticsProvider } from 'product-list/context/ProductListAnalyticsProvider/useProductListAnalyticsProvider'
import { useProductListProvider } from 'product-list/context/ProductListProvider/useProductListProvider'
import {
	type RefObject,
	useCallback,
	useEffect,
	useLayoutEffect,
	useSyncExternalStore,
} from 'react'

export const VIRTUAL_ITEM_CLASS = 'virtual-item'
export const VIRTUAL_LIST_CLASS = 'virtual-list'

function subscribeVirtualListChanges(callback: () => void) {
	const observer = new MutationObserver(([entry]) => {
		// Catching when a new virtual item is added to the list
		for (const item of entry.addedNodes) {
			if ((item as HTMLElement).className === VIRTUAL_ITEM_CLASS) {
				callback()
				break
			}
		}
	})

	const list = document.querySelector(`.${VIRTUAL_LIST_CLASS}`)
	if (!list) {
		return () => {}
	}

	observer.observe(list, {
		childList: true,
		subtree: true,
	})
	return () => {
		observer.disconnect()
	}
}

function getLastVirtualItem() {
	const lastItem = document.querySelector(`.${VIRTUAL_ITEM_CLASS}:last-child`)
	if (!lastItem) {
		return null
	}
	return lastItem
}

function getServerLastVirtualItem() {
	return null
}

export function useVirtualPages(total: number, perPage: number) {
	const { count, setCount } = useProductListProvider()
	const lastItem = useSyncExternalStore(
		subscribeVirtualListChanges,
		getLastVirtualItem,
		getServerLastVirtualItem
	)

	const { onListEndWasReached } = useProductListAnalyticsProvider()
	const itemElement: RefObject<Element> = {
		current: lastItem,
	}

	const next = useCallback(() => {
		setCount((prev) => {
			if (prev >= total) {
				return prev
			}
			return prev + perPage
		})
	}, [perPage, setCount, total])

	const entry = useIntersectionObserver(itemElement, {
		rootMargin: `0px 0px 100% 0px`,
		threshold: [0, 1],
	})

	useEffect(() => {
		if (count >= total) {
			onListEndWasReached?.()
		}
	}, [count, total, onListEndWasReached])

	useEffect(() => {
		if (entry?.isIntersecting) {
			next()
		}
	}, [entry, next])

	useLayoutEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])
}
