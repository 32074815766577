import {
	type ColorsFilter,
	FilterId,
	FilterType,
	type FiltersData,
	type GenericFilter,
	type PriceFilter,
	type SizesFilter,
	type UserFilter,
} from 'product-list/types/Filters'

import { ALL, NEW_COLLECTION, ON_SALE } from './constants'

const ID_PARTS_DELIMITER = '_'
const FIRST_ELEMENT = 1

export function getIdValue(str: string) {
	const idParts = str.split(ID_PARTS_DELIMITER)
	if (idParts.length > 1) {
		return idParts.slice(FIRST_ELEMENT).join(ID_PARTS_DELIMITER)
	}
	return str
}

export function getOptionId(
	type: FilterType,
	choice: string | number,
	id?: FilterId
) {
	const hasId = id !== undefined ? `-${id}` : ''
	return `${type}${hasId}_${choice.toString()}`
}

function genericMapper(filter: GenericFilter): UserFilter {
	const { type, id, choices, multipleChoice } = filter
	let options = choices.map((choice) => ({
		...choice,
		id: getOptionId(type, choice.id, id),
	}))
	/**
	 * On future we can get generic filters with multiple choices allowed
	 */
	options = !multipleChoice
		? [{ id: ALL, label: 'product.filters.allItems.radiobutton' }, ...options]
		: options
	return {
		...filter,
		options,
		type,
		id,
		multipleChoice,
		selection: multipleChoice ? [] : [ALL],
	}
}

function colorGroupsMapper(filter: ColorsFilter): UserFilter {
	const { type } = filter
	const selection: string[] = []
	const options = [...filter.colorGroups].map((choice) => ({
		...choice,
		id: getOptionId(type, choice.id),
	}))
	return {
		...filter,
		selection,
		options,
		type,
		label: 'product.filters.colors.title',
		multipleChoice: true,
	}
}

function sizesMapper(filter: SizesFilter): UserFilter {
	const { type } = filter
	const selection: string[] = []
	const options = filter.sizes.map((item) => ({
		id: getOptionId(type, item),
		label: item,
	}))
	return {
		...filter,
		selection,
		options,
		type,
		label: 'product.filters.sizes.title',
		multipleChoice: true,
	}
}

function priceMapper(filter: PriceFilter): UserFilter {
	const { type, onSale } = filter
	if (!onSale) {
		return {
			type,
			label: 'product.filters.prices.title',
			multipleChoice: true,
			selection: [],
			options: [],
		}
	}
	const selection: string[] = []
	const options = [
		{
			id: getOptionId(type, ON_SALE),
			label: 'product.filters.prices.sales.checkbox',
		},
		{
			id: getOptionId(type, NEW_COLLECTION),
			label: 'product.filters.prices.new.checkbox',
		},
	]
	return {
		selection,
		options,
		type,
		label: 'product.filters.prices.title',
		multipleChoice: true,
	}
}

export function mappers(filtersData: FiltersData): UserFilter[] {
	return filtersData.filters.flatMap((filter) => {
		switch (filter.type) {
			case FilterType.generic:
				return genericMapper(filter)
			case FilterType.colorGroups:
				return colorGroupsMapper(filter)
			case FilterType.sizes:
				return sizesMapper(filter)
			case FilterType.price:
				return priceMapper(filter)
			default:
				throw new Error('Invalid filter type')
		}
	})
}
