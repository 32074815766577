import type { ProductImageType } from 'product/types/Product'

export const IMAGE_ORDER: ProductImageType[] = [
	'O1',
	'F',
	'B',
	'D1',
	'D2',
	'D3',
	'D4',
	'D5',
	'D6',
	'D7',
	'D8',
	'D9',
	'R',
]

export const productIdRegex = /^\d{8}/g
