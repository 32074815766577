import type { ProductImageType } from 'product/types'
import type { ProductLocations } from 'product/types/ProductLocations'

export type FeedbackTypeOnAdd = 'BagPreview' | 'none'

export enum ProductCardVariant {
	General = 'general',
	Overview = 'overview',
}

export enum ProductCardIcon {
	None = 'none',
	Favorite = 'favorite',
	SizeSheet = 'size-sheet',
}

export enum ProductCardAnimation {
	None = 'none',
	FadeIn = 'fade-in',
	Slideshow = 'slideshow',
}

export interface ProductCardProps {
	productId: string
	lookId: string
	colorId: string
	imageSortCriteria: ProductImageType[]
	dataTestId: string
	isTwoColumnSize?: boolean
	className?: string
	priority?: boolean
	location: ProductLocations
	productListId?: string
	showDiscount?: boolean
	noDraggable?: boolean
	/** On add to cart callback */
	onAddToCart?: () => void
}

export const enum ColumnLayout {
	OneColumn = 1,
	TwoColumns = 2,
	FourColumns = 4,
}
