import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { getProductUrl } from 'links/pdp/utils/getProductUrl/getProductUrl'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useProductInfo } from 'product/services/productInfo/client'
import { useEffect, useState } from 'react'
import { env } from 'utils/envManager'

import { productIdRegex } from '../constants/product'

type UseSearchByProductReferenceConfig = {
	callbackOnSearch(): void
}

export const useSearchByProductReference = ({
	callbackOnSearch,
}: UseSearchByProductReferenceConfig) => {
	const { country } = useMasterData()
	const { navigate } = useNavigation()
	const { enabled: pdpEnabled } = useFeatureFlag(featureFlagKeys.GenesisPdp)
	const [productId, setProductId] = useState<string | null>(null)
	const [colorId, setColorId] = useState<string | null>(null)
	const [errorOnNavigate, setErrorOnNavigate] = useState<boolean>(false)
	const { data: product, error: productError } = useProductInfo({
		productId: productId || '',
		enabled: !!productId,
		language: country.languageISO,
		country: country.countryISO,
	})

	useEffect(() => {
		if (product && product.url) {
			const pdpHref = getProductUrl({
				product,
				colorId: colorId || '',
				enabled: pdpEnabled,
				countryISO: country.countryISO,
				languageISO: country.languageISO,
			})
			const pdpUrl = `${env.NEXT_PUBLIC_SITE_BASE_URL}${pdpHref}`

			callbackOnSearch()
			navigate(pdpUrl)
		}

		if (product && !product.url) {
			setErrorOnNavigate(true)
		}
	}, [product])

	const isProductId = (searchTerm: string): boolean => {
		return !!productIdRegex.exec(searchTerm)
	}

	const navigateToPdp = (productRef: string): void => {
		const [id, color] = productRef.split('-')

		if (color && !!color.match(/\d{2}/)) {
			setColorId(color)
		}

		setProductId(id)
	}

	useEffect(() => {
		if (productError) {
			setErrorOnNavigate(true)
		}
	}, [productError])

	return {
		isProductId,
		navigateToPdp,
		errorOnNavigate,
	}
}
