import {
	HorizontalScrollText,
	type HorizontalScrollTextProps,
} from '../HorizontalScrollText/HorizontalScrollText'

import styles from './HorizontalScrollHeaderInfo.module.scss'

export function HorizontalScrollHeaderInfo({
	titleProps,
	subtitleProps,
	status,
	showPaddingCarousel,
}: {
	titleProps?: HorizontalScrollTextProps
	subtitleProps?: HorizontalScrollTextProps
	status?: string
	showPaddingCarousel?: boolean
}) {
	return (
		<div className={styles.headerInfo}>
			{titleProps && <HorizontalScrollText {...titleProps} />}
			{subtitleProps && <HorizontalScrollText {...subtitleProps} />}
			{status && !showPaddingCarousel && (
				<p className={styles.status}>{status}</p>
			)}
		</div>
	)
}
