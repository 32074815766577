import { MINMAX, NO_SALES, SALES } from 'plp/constants/analytics'
import type {
	ApplyFiltersPayload,
	ColorOption,
	SalesCollectionIn,
	SalesCollectionOut,
} from 'plp/types/Analytics'
import {
	DEFAULT_ORDER,
	NEW_COLLECTION,
	ON_SALE,
	SERIALIZED_SELECTIONS_SEPARATOR,
} from 'product-list/context/ProductListFiltersProvider/constants'
import {
	FilterId,
	FilterType,
	PRICE_TYPE,
	type RangePickerValue,
	type SelectedFilters,
	type UserFilter,
} from 'product-list/types/Filters'

const FILTERS = 'filters'

function priceToAnalytics(
	userRange?: RangePickerValue,
	priceRange?: RangePickerValue
): typeof MINMAX | PRICE_TYPE | null {
	if (!userRange || !priceRange) {
		return null
	}
	if (userRange.min !== priceRange.min && userRange.max !== priceRange.max) {
		return MINMAX
	}
	if (userRange.min !== priceRange.min) {
		return PRICE_TYPE.MIN
	}
	if (userRange.max !== priceRange.max) {
		return PRICE_TYPE.MAX
	}
	return null
}

function sortToAnalytics(selectedOrder?: string): string | undefined {
	return selectedOrder !== DEFAULT_ORDER ? selectedOrder : undefined // Sort is removed if equals DEFAULT_ORDER, since it's the default value and not a user choice
}

function mapCollectionLabels(label: SalesCollectionIn): SalesCollectionOut {
	const collectionToAnalyticsName = {
		[ON_SALE]: SALES,
		[NEW_COLLECTION]: NO_SALES,
	}
	// If the label is not in the object, return it unchanged
	return (
		collectionToAnalyticsName[
			label as typeof ON_SALE | typeof NEW_COLLECTION
		] ?? label
	)
}

function getEnLabels(
	selection: string[],
	options: ColorOption[]
): (string | null)[] {
	return selection
		.map((selectedId) => {
			const selectedOption = options.find((option) => option.id === selectedId)
			return selectedOption ? selectedOption.enLabel : null
		})
		.filter((label) => label !== null)
}

function colorsToAnalytics(selectedFilters: UserFilter[]): (string | null)[] {
	const selectedColors = selectedFilters.find(
		(item: UserFilter) => item.type === FilterType.colorGroups
	)
	const selectedColorsById = selectedColors?.selection ?? []

	return getEnLabels(
		selectedColorsById,
		(selectedColors?.options as ColorOption[]) ?? []
	)
}

function sizeToAnalytics(
	selectedFilters: UserFilter[]
): (string | undefined)[] | undefined {
	return (
		selectedFilters
			.find((item) => item.type === FilterType.sizes)
			?.selection.map((id) =>
				id.split(SERIALIZED_SELECTIONS_SEPARATOR).at(1)
			) ?? undefined
	)
}

function collectionToAnalytics(
	selectedFilters: UserFilter[]
): (string | undefined)[] | undefined {
	return (
		selectedFilters
			.find((item) => item.type === FilterType.price)
			?.selection?.map((id) =>
				mapCollectionLabels(
					id.split(SERIALIZED_SELECTIONS_SEPARATOR).at(1) ?? ''
				)
			) ?? undefined
	)
}

function subfamiliesToAnalytics(
	selectedFilters: UserFilter[]
): (string | undefined)[] | null {
	const foundItem = selectedFilters.find(
		(item) =>
			item.type === FilterType.generic && item.id === FilterId.subfamilies
	)
	if (foundItem?.selection.length === 1 && foundItem?.selection[0] === '*') {
		return null
	}

	return (
		foundItem?.selection.map(
			(id) => foundItem.options.find((found) => found.id === id)?.label
		) ?? null
	)
}

export function filtersToAnalytics(
	appliedFilters: SelectedFilters
): ApplyFiltersPayload {
	const {
		selectedOrder,
		selectedFilters,
		selectedRange,
		selectedLimitRange,
		selectedCount,
	} = appliedFilters

	const size = sizeToAnalytics(selectedFilters)

	const color = colorsToAnalytics(selectedFilters)

	const price = priceToAnalytics(selectedRange, selectedLimitRange)

	const collection = collectionToAnalytics(selectedFilters)

	const sort = sortToAnalytics(selectedOrder)

	const subfamilies = subfamiliesToAnalytics(selectedFilters)

	const location = FILTERS

	const filteredResultsNumber = selectedCount

	const payload = {
		color,
		size,
		sort,
		price,
		collection,
		subfamilies,
		location,
		filtered_results_number: filteredResultsNumber,
	}

	return Object.fromEntries(
		Object.entries(payload).filter(
			([k, v]) => k && !!v && !(Array.isArray(v) && v.every((item) => !item))
		)
	) as ApplyFiltersPayload
}
