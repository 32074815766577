'use client'

import type { View } from 'product-list/types/View'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useMemo,
	useState,
} from 'react'

import type { SelectedFilters } from '../../types/Filters'

export interface ProductListAnalyticsContextType {
	layoutView: View | null
	setLayoutView?: Dispatch<SetStateAction<View | null>>
	onFiltersWasApplied?: (selected: SelectedFilters) => void
	onFiltersWasOpened?: () => void
	onFiltersWasClosed?: () => void
	onFiltersWasCleaner?: () => void
	onListEndWasReached?: () => void
	onZeroResults?: () => void
	onViewChange?: () => void
}

const defaultContext: ProductListAnalyticsContextType = {
	layoutView: null,
}
export const ProductListAnalyticsContext =
	createContext<ProductListAnalyticsContextType>(defaultContext)

export function ProductListAnalyticsProvider({
	events,
	hasServerFilters,
	children,
}: {
	hasServerFilters: boolean
	events?: {
		onFiltersWasApplied?: (
			selected: SelectedFilters,
			layoutView: View | null
		) => void
		onFiltersWasOpened?: (filtered: boolean, layoutView: View | null) => void
		onFiltersWasClosed?: (filtered: boolean, layoutView: View | null) => void
		onFiltersWasCleaner?: (filtered: boolean, layoutView: View | null) => void
		onListEndWasReached?: (filtered: boolean, layoutView: View | null) => void
		onZeroResults?: (filtered: boolean, layoutView: View | null) => void
		onViewChange?: (filtered: boolean, layoutView: View | null) => void
	}
	children: React.ReactNode
}) {
	const [layoutView, setLayoutView] = useState<View | null>(null)
	const {
		onFiltersWasApplied,
		onFiltersWasOpened,
		onFiltersWasClosed,
		onFiltersWasCleaner,
		onListEndWasReached,
		onZeroResults,
		onViewChange,
	} = events ?? {}

	const contextState = useMemo<ProductListAnalyticsContextType>(
		() => ({
			layoutView,
			setLayoutView,
			onFiltersWasApplied: (selected) => {
				onFiltersWasApplied?.(selected, layoutView)
			},
			onViewChange: () => {
				onViewChange?.(hasServerFilters, layoutView)
			},
			onZeroResults: () => {
				onZeroResults?.(hasServerFilters, layoutView)
			},
			onFiltersWasOpened: () => {
				onFiltersWasOpened?.(hasServerFilters, layoutView)
			},
			onFiltersWasClosed: () => {
				onFiltersWasClosed?.(hasServerFilters, layoutView)
			},
			onFiltersWasCleaner: () => {
				onFiltersWasCleaner?.(hasServerFilters, layoutView)
			},
			onListEndWasReached: () => {
				onListEndWasReached?.(hasServerFilters, layoutView)
			},
		}),
		[
			layoutView,
			onFiltersWasApplied,
			onZeroResults,
			hasServerFilters,
			onFiltersWasOpened,
			onFiltersWasClosed,
			onFiltersWasCleaner,
			onListEndWasReached,
			onViewChange,
		]
	)
	return (
		<ProductListAnalyticsContext.Provider value={contextState}>
			{children}
		</ProductListAnalyticsContext.Provider>
	)
}
