'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { useProductListProvider } from '../../context/ProductListProvider/useProductListProvider'
import { dataTestIds } from '../../utils/testIds'
import { Cell } from '../Cell/Cell'

import styles from './Grid.module.scss'

export interface GridItem {
	id: string
	render: React.ReactNode
	isFeatured: boolean
}

interface Props {
	index: number
	items: GridItem[]
}

export function Grid({ index, items }: Props) {
	const { isLargeOrGreater } = useResponsive()
	const { view } = useProductListProvider()
	const viewStyle = styles[view]

	return items.length ? (
		<ul
			className={`${styles.grid} ${viewStyle}`}
			data-testid={dataTestIds.gridView(view)}
		>
			{items.map((item, cellIndex) => (
				<Cell
					key={item.id}
					id={item.id}
					showAsFeatured={item.isFeatured && isLargeOrGreater}
					gridIndex={index}
					index={cellIndex}
				>
					{item.render}
				</Cell>
			))}
		</ul>
	) : null
}
