import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { PlpClientLink, isCatalogUrl } from '../../plp/client/PlpClientLink'

export type SiteClientLinkProps = ClientLinkProps & {
	className?: string
	sectionId?: string
}

export function SiteClientLink(props: SiteClientLinkProps): React.ReactNode {
	const { children, className, sectionId, href, ...rest } = props

	const isCatalog = isCatalogUrl(href) && sectionId

	// TODO: pending include PdpClientLink
	return isCatalog ? (
		<PlpClientLink
			href={href}
			{...rest}
			catalogId={sectionId}
			className={className}
		>
			{children}
		</PlpClientLink>
	) : (
		<ClientLink href={href} {...rest} className={className}>
			{children}
		</ClientLink>
	)
}
