'use client'

import { useHeaderHeight } from 'hooks/useHeaderHeight/useHeaderHeight'
import { IconSingle } from 'icons/components/IconSingle'
import { IconSquare01L } from 'icons/components/IconSquare01L'
import { IconSquare02L } from 'icons/components/IconSquare02L'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useProductListAnalyticsProvider } from 'product-list/context/ProductListAnalyticsProvider/useProductListAnalyticsProvider'
import { useProductListFiltersProvider } from 'product-list/context/ProductListFiltersProvider/useProductListFiltersProvider'
import { useProductListProvider } from 'product-list/context/ProductListProvider/useProductListProvider'
import type { View } from 'product-list/types/View'
import { dataTestIds } from 'product-list/utils/testIds'
import { type ReactNode, useCallback, useMemo, useState } from 'react'
import { cx } from 'utils/cx'

import { FiltersButton } from '../Filters/FiltersButton/FiltersButton'
import { scrollToProductListHook } from '../ProductListHook/ProductListHook'

import styles from './Sticky.module.scss'
import fukkuClasses from 'fukku/styles/classes.module.scss'

interface StickyProps {
	displayViewSelector: boolean
	displayFilters: boolean
}

export function Sticky({ displayFilters, displayViewSelector }: StickyProps) {
	const { onViewChange } = useProductListAnalyticsProvider()
	const { t } = useLabels()

	const { view, setView } = useProductListProvider()
	const { reset } = useProductListFiltersProvider()

	const [show, setShow] = useState(false)
	const { headerHeight } = useHeaderHeight()

	const onFilterClick = useCallback(() => {
		setShow((prev) => !prev)
	}, [])

	const onClose = useCallback(() => {
		setShow(false)
		reset()
	}, [reset])

	const labels: Record<View, string> = {
		inspirational: t('accessibility.plp.gridview.firstView.icon'),
		standard: t('accessibility.plp.gridview.secondView.icon'),
		overview: t('accessibility.plp.gridview.thirdView.icon'),
	}

	const viewIcons = useMemo<Record<View, { icon: ReactNode; label: string }>>(
		() => ({
			inspirational: {
				icon: <IconSingle alt={labels.inspirational} />,
				label: labels.inspirational,
			},
			standard: {
				icon: <IconSquare01L alt={labels.standard} />,
				label: labels.standard,
			},
			overview: {
				icon: <IconSquare02L alt={labels.overview} />,
				label: labels.overview,
			},
		}),
		[labels.inspirational, labels.overview, labels.standard]
	)

	const handleClickView = useCallback(
		(newView: View) => {
			onViewChange?.()
			setView(newView)
			scrollToProductListHook({ isSmooth: true })
		},
		[onViewChange, setView]
	)

	if (!displayFilters && !displayViewSelector) {
		return null
	}

	return (
		<div style={{ top: `${headerHeight}px` }} className={styles.sticky}>
			<div className={styles.inner}>
				{displayFilters && (
					<FiltersButton
						show={show}
						onFilterClick={onFilterClick}
						onClose={onClose}
					/>
				)}
				{displayViewSelector && (
					<fieldset className={styles.viewSelector}>
						<legend className={fukkuClasses.srOnly}>
							{t('accessibility.icon.griedview.plp.text')}
						</legend>
						{Object.entries(viewIcons).map(([currentView, { icon, label }]) => {
							const id = `view-${currentView}`
							return (
								<label
									key={`view-option-${currentView}`}
									className={cx(
										styles.viewItem,
										currentView !== view && styles.unselected
									)}
									title={label}
									data-testid={
										currentView === view
											? dataTestIds.productListViewSelected
											: dataTestIds.productListViewUnselected
									}
								>
									<input
										id={id}
										data-testid={dataTestIds.productListView(
											currentView as View
										)}
										type='radio'
										name='product-list-view'
										onClick={() => handleClickView(currentView as View)}
									/>
									{icon}
								</label>
							)
						})}
					</fieldset>
				)}
			</div>
		</div>
	)
}
