import { Tooltip } from 'fukku/Tooltip'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { AddButton } from 'product-card/components/shared/ProductSizes/AddToBag/AddToBagButton/AddButton'
import { BackInStockButton } from 'product-card/components/shared/ProductSizes/BackInStockButton/BackInStockButton'
import { useProductCardRefContext } from 'product-card/hooks/useProductCardRefContext'
import { PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY } from 'product/constants/constants'
import type {
	Product,
	ProductFullSizeInfo,
	ProductSize,
} from 'product/types/Product'
import { useCallback } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import styles from 'product-card/components/shared/ProductSizes/AddToBag/AddToBagButton/AddToBagButton.module.scss'

interface AddToBagButtonProps {
	product?: Product
	size: ProductFullSizeInfo | ProductSize
	className?: string
	children?: React.ReactNode
	onClose?: () => void
}

export const AddToBagButton = ({
	product,
	size,
	className,
	children,
	onClose,
}: AddToBagButtonProps) => {
	const { t } = useLabels()
	const { isTouchable, isLargeOrGreater } = useResponsive()
	const { productCardRef } = useProductCardRefContext()

	const handleOnCloseAndBlur = useCallback(() => {
		productCardRef.current?.querySelector('a')?.focus()
		onClose?.()
	}, [onClose])

	const isProductFullSizeInfo = PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY in size

	if (isProductFullSizeInfo) {
		const isLastUnitsTooltip = size.lastUnits
		const isBackInStock = !size.available && product

		if (isLastUnitsTooltip && !isTouchable && isLargeOrGreater) {
			return (
				<Tooltip
					position='bottom'
					content={
						<p className={styles.tooltipContent}>
							{t('pdp.sizeSelector.lastItems.text')}
						</p>
					}
					boundary={productCardRef}
				>
					<div id='lastUnits-container'>
						<AddButton size={size} className={className}>
							{children}
						</AddButton>
					</div>
				</Tooltip>
			)
		}

		if (isBackInStock && !isTouchable && isLargeOrGreater) {
			return (
				<Tooltip
					position='bottom'
					content={
						<p className={styles.tooltipContent}>
							{t('plp.sizeSelector.notifyMe.get.tooltip')}
						</p>
					}
					boundary={productCardRef}
				>
					<div id='backinstock-container'>
						<BackInStockButton
							size={size}
							product={product}
							className={className}
							onClose={handleOnCloseAndBlur}
						>
							{children}
						</BackInStockButton>
					</div>
				</Tooltip>
			)
		}

		if (isBackInStock && !isLargeOrGreater) {
			return (
				<BackInStockButton
					size={size}
					product={product}
					className={className}
					onClose={onClose}
				>
					{children}
				</BackInStockButton>
			)
		}
	}

	return (
		<AddButton size={size} className={className} onClose={onClose}>
			{children}
		</AddButton>
	)
}
