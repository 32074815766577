import { useSetAbTestRequest } from 'ab-test/hooks/useSetAbTestRequest/useSetAbTestRequest'
import { useStateAbTest } from 'ab-test/hooks/useStateAbTest/useStateAbTest'
import type { FeatureFlagDecision } from 'ab-test/types'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import type { AbTestKeys } from 'types/constants/AbTestKeys'

import { handleDecision } from '../../utils/handleDecision'

interface UseAbTestProps {
	key: AbTestKeys
	attributes?: Record<string, unknown>
	defaultDecision?: Partial<FeatureFlagDecision>
	preventRequestSDK?: boolean // This is used to prevent the SDK from being requested
}

const abTestsInitialized: AbTestKeys[] = []

function shouldRequestSDK(abRequested: boolean, preventRequestSDK: boolean) {
	return !abRequested && !preventRequestSDK
}

function getAbDecision(
	abTest: FeatureFlagDecision | undefined,
	defaultDecision: Partial<FeatureFlagDecision> | undefined
) {
	return abTest
		? {
				enabled: abTest?.enabled,
				variables: abTest?.variables,
			}
		: defaultDecision
}

function requestSDK(
	abRequested: boolean,
	preventRequestSDK: boolean,
	setAbRequested: Dispatch<SetStateAction<boolean>>
) {
	if (shouldRequestSDK(abRequested, preventRequestSDK)) {
		setAbRequested(true)
	}
}

export const useAbTest = ({
	key,
	attributes,
	defaultDecision,
	preventRequestSDK = false,
}: UseAbTestProps) => {
	const { abTestReady, abRequested } = useStateAbTest()
	const setAbRequested = useSetAbTestRequest()

	const [abTest, setAbTest] = useState<FeatureFlagDecision | undefined>()

	useEffect(() => {
		requestSDK(abRequested, preventRequestSDK, setAbRequested)
	}, [preventRequestSDK])

	useEffect(() => {
		if (abTestReady) {
			const handleAbTest = async () => {
				const abTestActivated = abTestsInitialized.includes(key)
				const getUserContext = (await import('ab-test/utils/userContext'))
					.getUserContext
				const userContext = getUserContext()

				if (userContext) {
					const decision = handleDecision({
						userContext,
						key,
						abTestActivated,
						attributes,
					})
					if (!abTestActivated) {
						abTestsInitialized.push(key)
					}
					if (decision) {
						setAbTest(decision)
					}
				}
			}
			handleAbTest()
		}
	}, [abTestReady])

	return getAbDecision(abTest, defaultDecision)
}
